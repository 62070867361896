
// // Import I18Next
import { i18n } from "i18next";
// English Import 
import login_en from "./en/en.login.json";
import recovery_en from "./en/en.recovery.json";
import change_en from "./en/en.change.json";
import dashboard_en from "./en/en.dashboard.json";
import app_en from "./en/en.app.json";
import appSidebar_en from "./en/en.appSidebar.json";
import settings_en from "./en/en.settings.json";
import table_en from "./en/en.table.json";
// Spanish Import 
import login_es from "./es/es.login.json";
import recovery_es from "./es/es.recovery.json";
import change_es from "./es/es.change.json";
import dashboard_es from "./es/es.dashboard.json";
import app_es from "./es/es.app.json";
import appSidebar_es from "./es/es.appSidebar.json";
import settings_es from "./es/es.settings.json";
import table_es from "./es/es.table.json";

export function getTranslation(i18next:i18n) {

  const localLanguage = localStorage.getItem("language");
  const languageNav = window.navigator.language.substring(0,2) ?? "en";
  !localLanguage ? localStorage.setItem('language',languageNav) : localLanguage !== "es" ? localStorage.setItem('language','en') : localStorage.setItem('language',localLanguage);
  const Language = localStorage.getItem("language")?.toString();

  return i18next.init({
    interpolation: { escapeValue: false },
    lng: Language,
    resources: {
      es: {
        login: login_es,
        recovery: recovery_es,
        change: change_es,
        dashboard: dashboard_es,
        app: app_es,
        appSidebar: appSidebar_es,
        setting: settings_es,
        table:table_es
      },
      en: {
        login: login_en,
        recovery: recovery_en,
        change: change_en,
        dashboard: dashboard_en,
        app: app_en,
        appSidebar: appSidebar_en,
        setting: settings_en,
        table:table_en
      },
    },
  });
}
