import { RoutesMain } from "./routes/_routes.main";
import { useLocation } from "react-router-dom";
// I18next 
import { useTranslation} from "react-i18next";
// Utils import
import { dataDecrypt } from "./utils/utils.encrypt-decrypt";
//Elements import
import { LoaderPageType } from "./elements/_elements.main";
// Components import
import { AppNavbar } from "./components/app/AppNavbar";
import { AppSidebar } from "./components/app/AppSidebar";
import { AlertMessageType } from "./elements/alerts/AlertMessageType";

//Store import
import { alertsMutations, alertsState } from './store/alerts/_alerts.main';
import { userState } from "./store/user/_user.main";
import { loginState } from "./store/login/_login.main";

export const App = () => {
  // I18Next
  const [t,i18n] = useTranslation("login")

  // States
  const { user } = userState();
  const { loadingPage } = loginState();
  // Store
  const { infoAlert, showAlert } = alertsState();
  const { setShowAlert } = alertsMutations();
  // Routes
  const location = useLocation();
  // Validations
  const auth = user && dataDecrypt(user).logged;
  const inside = () => location.pathname !== "/login" && auth;

  const handleClick = (payload: any) => {
    payload && setShowAlert(false);
  };

  return (
    <>
      <div className={`app ${inside() && "app--inside"}`}>
        {inside() && <AppNavbar />}
        <RoutesMain auth={auth} />
        {/* {inside() && <AppSidebar />} */}
        {showAlert && 
          <AlertMessageType content={ infoAlert } onClick={ handleClick } outsideClick={ infoAlert?.outsideClick }/>
        }
      </div>
      {loadingPage.status && <LoaderPageType text={t("LOGIN.LOADER")} type={loadingPage.type} />}
    </>
  );
};
