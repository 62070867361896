import { ApexOptions } from 'apexcharts';
import React from 'react'

// External libraries
import Chart from "react-apexcharts";

interface IFDashboardAbandonments{
  title:string;
  series:number[];
  labels:string[];
  colors:string[];
}

const defaultProps = {
  title:'',
  series: [],
  labels: [],
  colors: ["#2bebc8", "#6746ed"],
}

export const DashboardAbandonments = (props:IFDashboardAbandonments) => {

  const chartType: "pie" | "donut" = 'donut'
  const series = props.series
  const options:ApexOptions = {
    dataLabels: {
      enabled: false
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 340,
        },
        legend: {
          show: false
        }
      }
    }],
    labels: props.labels,
    colors: props.colors,
    fill: {
      colors: props.colors
    },
    legend: {
      position: 'right',
      offsetY: 10,
      height: 230,
      labels:{
        colors: "#fff"
      }
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
            },
            value:{
              color: "#fff",
              fontWeight: 600,
              offsetY: 3
            },
            total: {
              label: "Total",
              fontWeight: 400,
              showAlways: true,
              show: true,
              color: "#fff"
            }
          }
        }
      }
    },
  }

  return (
    <div className='text-white'>
      <h4 className='uppercase text-white text-center mb-3'>{ props.title }</h4>
      <Chart
        options={ options }
        series={ series }
        type={ chartType }
        width={380}
      />
    </div>
  )
}

DashboardAbandonments.defaultProps = defaultProps
