// Styles import
import "../../assets/styles/elements/elements.buttons.css";
// Images import
import iconLoading from "../../assets/icons/loading.svg";

//Props
interface IFButtonText {
  type: "button" | "submit";
  text: string;
  color: string;
  height: string;
  width: string;
  classes: string;
  disabled: boolean;
  loading: boolean;
  onClick: () => void;
};
const defaultProps = {
  type: "button",
  text: "Button text",
  color: "primary",
  height: "h-12",
  width: "w-full md:w-72",
  classes: "",
  disabled: false,
  loading: false,
  click: null,
};

export const ButtonText = (props: IFButtonText) => {
    
  const color = () => {
    const options: any = {
      primary: "btn-text--primary",
      secondary: "btn-text--secondary",
      error: "btn-text--error",
    };
    return options[props.color];
  };

  return (
    <>
      <button
        type={props.type}
        disabled={props.disabled || props.loading}
        className={`
          btn-text
          ${color()}
          ${props.width}
          ${props.height}
          ${props.classes}
        `}
        onClick={props.onClick}
      >
        {!props.loading ? (
          <span>{props.text}</span>
        ) : (
          <img src={iconLoading} alt="loading" className="btn-text__loader" />
        )}
      </button>
    </>
  );
};
ButtonText.defaultProps = defaultProps;
