// React import
import { useEffect, useRef, useState } from "react";
// External libraries
import Chart from "react-apexcharts";

// I18Next Import
import { useTranslation } from "react-i18next";
// Components import
import { CardClassic } from "../../elements/_elements.main";

// interface
interface Props {
  title: string;
  startDate: string;
  endDate: string;
  value: number;
  chartName: string;
  description: string;
  series: any;
  categories: [];
  onSubmit(e: any): void;
  chartType?:
    | "line"
    | "area"
    | "bar"
    | "histogram"
    | "pie"
    | "donut"
    | "radialBar"
    | "scatter"
    | "bubble"
    | "heatmap"
    | "treemap"
    | "boxPlot"
    | "candlestick"
    | "radar"
    | "polarArea"
    | "rangeBar";
}

const defaultProps = {
  title: "Información chart",
  startDate: '',
  endDate: '',
  value: 0,
  chartName:'',
  description: "",
  series: {},
  categories: [],
  onSubmit: null,
  chartType: "bar",
};

export const DashboardCharts = (props: Props) => {
  // react
  const [showChart, setShowChart] = useState(false);
  const chartElement = useRef(null);
  //I18Next
  const [t, i18n] = useTranslation("dashboard");
  // Funtion
  const handleObserver = (payload: any) => {
    payload.forEach(async (entry: any) => {
      if (entry.isIntersecting) {
        await entry.target.classList.add("chart-animated--show");
        setShowChart(true);
      } else {
        await entry.target.classList.add("chart-animated--show");
        setShowChart(false);
      }
    });
  };
  // Constants
  const labelColor = "#858689";
  const options = {
    chart: {
      id: props.title,
      toolbar: {
        tools: {
          download: false,
        },
      },
    },
    xaxis: {
      categories: props.categories,
      labels: {
        style: {
          colors: labelColor,
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
        },
      },
    },
    colors: ["#2BEBC8"],
    plotOptions: {
      bar: {
        borderRadius: 7,
      },
    },
  };

  useEffect(() => {
    let chart: any = chartElement.current;

    const observer = new IntersectionObserver(handleObserver, {
      rootMargin: "0px",
      threshold: 0.01,
    });
    observer.observe(chart);
    return () => {
      observer.disconnect();
    };
  }, [chartElement.current]);

  return (
    <>
      <div className="dashboard-description">
        <CardClassic
          classes="card-animated col-span-12 sm:col-span-3"
          title={props.title}
          value={props.value}
          description={props.description}
        />
        <div className="dashboard-description__section">
          <h2 className="dashboard-description__title">{props.title}</h2>
          <div
            ref={chartElement}
            className="dashboard-description__chart chart-animated"
          >
            {showChart ? (
              <>
                <Chart
                  options={options}
                  series={props.series}
                  type={props.chartType}
                  width="100%"
                  height="240"
                />
              </>) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};
DashboardCharts.defaultProps = defaultProps;
