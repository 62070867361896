import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModalClassic } from "../../elements/_elements.main";
import GoogleMapReact from "google-map-react";
import {
	faIdCardClip,
	faMapPin,
	faMobilePhone,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { COLORS_TYPE_BG } from "../../types/types.dashboards";
import { useFormatDate } from "../../utils/utils.date";
import { dashboardAction } from "../../store/dashboard/_dashboard.main";
import { useEffect, useState } from "react";
import { DashboardImgMap } from "./DashboardImgMap";
const KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
interface IFDashboardModalMap {
	data: any;
	onClose: () => void;
}
export const DashboardModalMap = (props: IFDashboardModalMap) => {
	const [t, i18n] = useTranslation("dashboard");
	const { requestImage } = dashboardAction();
	const [srcImg, setSrcImg] = useState("");
	const [showPhoto, setShowPhoto] = useState<boolean>(true);

	const defaultProps = {
		center: {
			lat: props.data.eventLocations.latitude,
			lng: props.data.eventLocations.longitude,
		},
		zoom: 15,
	};
	const { format: formatDate } = useFormatDate({
		formatIn: "YYYY-MM-DDTHH:mm:ss",
		formatOut: "DD MMMM YYYY, h:mm a",
	});
	const loadImg = () => {
		requestImage({ id: props.data.id, UserId: props.data.userAppId }).then(
			(resp: any) => {
				setSrcImg(resp.image);
			}
		);
	};
	const PinComponent = (props: any) => (
		<div title={`Id: ${props.text}`} className="flex">
			<FontAwesomeIcon
				onClick={() => setShowPhoto(true)}
				className="text-secondary text-3xl cursor-pointer"
				icon={faMapPin}
			/>
			<DashboardImgMap
				img={srcImg}
				showImg={showPhoto}
				onClick={() => setShowPhoto(false)}
			/>
		</div>
	);
	useEffect(() => {
		loadImg();
	}, []);
	return (
		<>
			<ModalClassic showClose={true} size="large" outsideClick={props.onClose}>
				<div className="text-left" style={{ height: "500px", width: "100%" }}>
					<div className="flex">
						<h1 className="text-2xl font-bold">
							{t(`DASHBOARD_MODAL_MAP.TITLE_MODAL_PROCESS`)}
						</h1>
						<span
							className={`${
								(COLORS_TYPE_BG as any)[props.data.result]
							} dashboard-modal-map__state--badge`}
						>
							{props.data.result}
						</span>
					</div>
					<span className="font-thin">{props.data.id}</span>
					<div className="dashboard-modal-map__container--main">
						<div className="w-[30%] mt-5 space-y-6">
							<div className="dashboard-modal-map__container--icon">
								<FontAwesomeIcon icon={faIdCardClip} />{" "}
								<p className="pl-2">{props.data.userAppId}</p>
							</div>
							{props.data.device && (
								<div className="dashboard-modal-map__container--icon">
									<FontAwesomeIcon icon={faMobilePhone} />{" "}
									<p className="pl-3">{props.data.device}</p>
								</div>
							)}
							{props.data.eventLocations.ip && (
								<div className="dashboard-modal-map__container--icon">
									<p className="font-extrabold">I.P</p>{" "}
									<p className="">{props.data.eventLocations.ip}</p>
								</div>
							)}
							<div className="mt-9">
								<h1 className="font-bold">
									{t("DASHBOARD_MODAL_MAP.TITLE_MODAL_OBSERVATIONS")}:
								</h1>
								<p className="dashboard-modal-map__observation">
									{props.data.observation}
								</p>
							</div>
						</div>
						{props.data.eventLocations.latitude ? (
							<div className="w-[70%] h-[70%]">
								{formatDate(props.data.date)}
								<GoogleMapReact
									bootstrapURLKeys={{ key: KEY ?? "" }}
									defaultCenter={defaultProps.center}
									defaultZoom={defaultProps.zoom}
								>
									<PinComponent
										lat={props.data.eventLocations.latitude}
										lng={props.data.eventLocations.longitude}
										text={props.data.userAppId}
									/>
								</GoogleMapReact>
								{props.data.eventLocations.country !== null &&
									props.data.eventLocations.state !== null &&
									props.data.eventLocations.city !== null && (
										<p className="dashboard-modal-map__location">
											{`${props.data.eventLocations.country} | ${props.data.eventLocations.state} | ${props.data.eventLocations.city}`}
										</p>
									)}
							</div>
						) : (
							<div className="dashboard-modal-map__msg--not-found">
								<p className="italic">{`${t(
									"DASHBOARD_MODAL_MAP.MESSAGE_NOT_DATA"
								)}`}</p>
							</div>
						)}
					</div>
				</div>
			</ModalClassic>
		</>
	);
};
