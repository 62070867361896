//peticion
import create from "zustand";
//Types import
import { BUTTON_OPTIONS } from "../../types/types.dashboards";
// Import main store
import { consumersState } from "./_consumers.main";

export const consumersMutations = create(() => ({

    setConsumers: (payload: any) => {
        consumersState.setState({
            consumers: payload,
        });
    },

    setLoadingGetConsumers: (payload: any) => {
        consumersState.setState({
            loadingGetConsumers: payload,
        });
    },

    setDataConsumer: (payload: any) => {
        consumersState.setState({
            dataConsumer: payload,
        });
    },

    setLoadingGetDataConsumer: (payload: any) => {
        consumersState.setState({
            loadingGetDataConsumer: payload,
        });
    },
    
    setViewDataConsumer: (payload: any) => {
        consumersState.setState({
            viewDataConsumer: payload,
        });
    },

    setCurrentOption: (payload: BUTTON_OPTIONS) => {
        consumersState.setState({
            currentOption: payload,
        });
    },

}))