import React from "react";

interface IFSelectClassic {
	name: string;
	selected: string;
	classes: string;
	onChange(e: any): void;
	options: {
		value: string | number;
		text: string;
	}[];
}

const defaultProps = {
	name: "",
	selected: null,
	classes: "",
	onChange: null,
	options: null,
};

export const SelectClassic = (props: IFSelectClassic) => {
	return (
		<>
			<select
				defaultValue={props.selected}
				name={props.name}
				onChange={props.onChange}
				className={`${props.classes} select`}
			>
				{props.options.map((option) => (
					<option
						key={option.value}
						className="bg-gray-50"
						value={option.value}
					>
						{option.text}
					</option>
				))}
			</select>
		</>
	);
};

SelectClassic.defaultProps = defaultProps;
