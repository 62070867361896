// Images import
import notfound from "../../assets/img/imgNotfound.jpeg";
import { useFormatDate } from "../../utils/utils.date";

interface IFCardConsumer {
	title: string;
	description: string;
	color: string;
	img: string | null;
	onClick: () => void;
}
const defaultProps = {
	title: "Insert title",
	description: "Insert description",
	color: "",
	img: null,
	onClick: null,
};

const colorRing = {
	red: "ring-red-500",
	yellow: "ring-yellow-400",
	green: "ring-primary",
};

export const CardConsumer = (props: IFCardConsumer) => {
	const { format: formatDate } = useFormatDate({
		formatIn: "YYYY-MM-DDTHH:mm:ss",
		formatOut: "DD MMM YYYY, h:mm a",
	});
	return (
		<>
			<div className="card-consumer" onClick={props.onClick}>
				<img
					src={props.img ? `data:image/png;base64,${props.img}` : notfound}
					alt={props.title}
					className={`card-consumer__img ${(colorRing as any)[props.color]}`}
				/>
				<div className="card-consumer__content">
					<h5 className="card-consumer__title">{props.title}</h5>
					<h6 className="card-consumer__description">
						{formatDate(props.description)}
					</h6>
				</div>
			</div>
		</>
	);
};
CardConsumer.defaultProps = defaultProps;
