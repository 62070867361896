// React
import { useEffect, useRef, useState } from "react";

//Props
interface Props {
  title: string;
  value: number;
  description: string;
  colorText: string;
  colorNumber: string;
  classes: string;
  
  onChange(e: any): void;
  onClick: () => void;
};
const defaultProps = {
  title: "Ingrese titulo",
  description: "",
  number: 0,
  classes: "",
  value: "0",
  colorText: "",
  colorNumber: "",
  onChange: null,
  onClick: null,
};

export const CardClassic = (props:Props) => {

  const [processNumber, setProcessNumber] = useState('');
  //Element Observable
  const cardContainer = useRef(null);
  
  const handleObserver = (payload:any)=>{
    payload.forEach(async (entry:any) => {
      if (entry.isIntersecting) {
        await entry.target.classList.add("card-animated--show")
        loadCircle()
        numberIncrease(value, counterOperator)
      }else{
        await entry.target.classList.remove("card-animated--show")
      }
    });
  }

  //Constants and variables for circle design
  const circleElement = useRef(null)
  const transitionDuration = 900;
  const size = 118;
  const cx = size / 2;
  const cy = size / 2;
  const r = ( size / 2) - 7;
  const hundreds = 100;
  const thousands = 1000;
  const million = 1000000;

  //Get operator type (hundreds, thousands, million)
  let value = props.value;
  let operator:number;
  if(value > hundreds && value < thousands){ // val > 100 && < 1K
    operator = thousands;
  }else if(value > thousands && value < million){ // val > 1K && < 1M
    operator = million;
  }else if(value > million){ // val > 1M
    operator = 10000000;
  }else {
    operator = hundreds;
  }
  //Determine counter type
  const counterOperator:number = value > 500 ? 100 : 0;
  //Transform number: >= 1000 to 1k+ and >= 1000000 to 1M+
  const transformNumber = (payload:any) => {
    let number;
    let suffix;
    if(payload > thousands && payload < million){
      number = payload/thousands
      suffix = "k+"
    }else if(payload > million){
      number = payload / million
      suffix = "M+"
    }else{
      number = payload
      suffix = ''
    }
    return number + suffix
  };

  const loadCircle = () => {
    const progress:any = circleElement.current;
    const radius = progress.r.baseVal.value;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference * (operator - value) / operator;    
    progress.style.setProperty('--transitionDuration', `${transitionDuration}ms`);
    progress.style.setProperty('--initialStroke', circumference);  
    setTimeout(() => progress.style.strokeDashoffset = offset, operator);
  };

  //Generate increase effect according to the accountant
  const numberIncrease = (number:number, counter:number) =>{
    let interval = transitionDuration / number;
    let count = 0;  
    let intervalIncrease = setInterval(() => {
      if (count >= number) {
        clearInterval(intervalIncrease);
      }
      setProcessNumber(transformNumber(count))     
      if(counter > 0){
        count += counter
      }else{
        count++
      }
    }, interval);
  };
  
  useEffect(()=>{
    const card:any = cardContainer.current
    const observer = new IntersectionObserver(handleObserver, {
      rootMargin: '256px',
      threshold: 0.1
    });
    observer.observe(card);
    return () => {
      observer.disconnect();
    }
  
  },[value, cardContainer.current]);
  
  return (
    <div ref={ cardContainer } className={`card-classic ${props.classes}`}>
      <div className="card-classic__content">
        <div className="relative">
          <h3 className="card-classic__title">{props.title}</h3>
          <svg width={ size } height={ size } className="circle">
            <circle cx={ cx } cy={ cy } r={ r } className="circle__progress circle__progress--path"></circle>
            <circle ref={ circleElement } cx={ cx } cy={ cy } r={ r } className="circle__progress circle__progress--fill"></circle>
          </svg>
          <h2 className="card-classic__number">{ processNumber }</h2>
        </div>
        {/* <p className="card-classic__description">{ props.description }</p> */}
      </div>
    </div>
  )
};
CardClassic.defaultProps = defaultProps;
