//peticion
import create from "zustand";
// Utils import
import { restPost } from "../../utils/utils.rest";
import { dataDecrypt } from "../../utils/utils.encrypt-decrypt";
// Store import
import { consumersMutations } from "./consumers.mutations";
import { dashboardMutations } from "../../store/dashboard/_dashboard.main";
import { userState } from "../user/user.state";
// Endpoints import
import { URL_GET_FILTERED_CONSUMERS, URL_GET_INITIAL_CONSUMERS, URL_GET_CONSUMER } from "../../app.tickets";
import { BUTTON_OPTIONS } from "../../types/types.dashboards";

const setConsumersMutations = consumersMutations.getState();
const setDashboardMutations = dashboardMutations.getState();
const getUserState = userState.getState();

export const consumersActions = create(() => ({
  getInitialConsumers: async () => {
    setConsumersMutations.setLoadingGetConsumers(true);
    try {     
      const dataInitialConsumers = {
        rows: 10,
        eventTypeId: 700,
      };
      const res = await restPost({
        url: URL_GET_INITIAL_CONSUMERS,
        data: dataInitialConsumers,
      });      
      const dataRes = await res.data.data;
      setConsumersMutations.setConsumers(dataRes);
    } catch (error) {
      console.error(error);
    } finally {
      setConsumersMutations.setLoadingGetConsumers(false);
    }
  },
  getConsumers: async (payload: string) => {
    if (payload.length >= 3) {
      setConsumersMutations.setLoadingGetConsumers(true);
      try {
        const client = await dataDecrypt(getUserState.user).fkClientId;
        const data = {
          userAppId: payload,
          fkClientId: client,
        };
        const res = await restPost({
          url: URL_GET_FILTERED_CONSUMERS,
          data: data,
        });
        const dataRes = await res.data.data;
        setConsumersMutations.setConsumers(dataRes);
      } catch (error) {
        console.log(error);
      } finally {
        setConsumersMutations.setLoadingGetConsumers(false);
      }
    }
  },
  getConsumer: async (payload: string) => {
   //TODO: Redirect to dashboard page
   try {
     const data = {
       PageNumber: 1,
       RowsOfPage: 10,
       FkUserAppId: payload,
      };
      
      setConsumersMutations.setCurrentOption(BUTTON_OPTIONS.EVENTS)
      const res = await restPost({ url: URL_GET_CONSUMER, data: data });
      const dataRes = await res.data.data;
      setDashboardMutations.setEvents(res.data.data.events)

      // const event:[] = res.data.data.events
      // event.forEach((element:any) => {
      //   console.log(element);        
      // });


    } catch (error) {
      console.log(error);
    } finally {
    }
  }
}));
