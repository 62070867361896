import React, { useRef } from 'react'

// Icons import
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

interface IFRatingStars {
    icon:any;
    getRaiting(e:number):void
}

const defaultProps = {
    icon: faStar,
    getRaiting: null
}

export const RatingStars = (props:IFRatingStars) => {

    const wrapperRating = useRef<HTMLDivElement>(null)
    const itemReference = useRef<Array<HTMLDivElement>>([])
    itemReference.current = []

    const addElementReference = (payload:any) => {
        if(payload && !itemReference.current.includes(payload)){
            itemReference.current.push(payload)
        }
    }

    const handleClick = (payload:number)=>{
        wrapperRating.current?.classList.add('raiting-stars--disabled')
        const rating:number = payload + 1
        itemReference.current.forEach((star, index)=>{
            if(index <= payload){
                star.classList.add("raiting-stars__item--active")
            }
        })
        props.getRaiting(rating)
    }

  return <>
    <div ref={ wrapperRating } className="raiting-stars">
        {
            [...Array(5)].map((ele, index) => {

                return  <div className="raiting-stars__item" key={ index } ref={ addElementReference } onClick = {(e:any)=>{ handleClick(index) }}>
                            <FontAwesomeIcon
                                icon={props.icon}
                            />
                        </div>
                
            })
        }
    </div>
  </>
}

RatingStars.defaultProps = defaultProps