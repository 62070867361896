import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const DashboardImgMap = (props: any) => {
	return (
		<>
			{props.showImg && (
				<div className="dashboard-img-map__card--photo">
					<div
						onClick={props.onClick}
						className="modal__float-btn-close"
					>
						<FontAwesomeIcon className="m-auto text-white" icon={faClose} />
					</div>
					<img alt="" width={150} src={`data:image/jpeg;base64,${props.img}`}></img>
				</div>
			)}
		</>
	);
};
