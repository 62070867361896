import create from "zustand";
// JWT
import jwt_decode from "jwt-decode";

//Types import
import { IFLoaderPageType } from "../../types/types.login";
// Import main store
import { loginState,loginActions } from "./_login.main";
import { alertsMutations } from "../alerts/_alerts.main";
//Store user import
import { userMutations } from "../user/_user.main";
//Utils import
import { removeItemLocal } from "../../utils/utils.storage";

import { SAVE_AUTH_LOCAL_STORAGE } from "../../app.tickets";


const setUserMutation = userMutations.getState();
const setAlertsMutation = alertsMutations.getState();

export const loginMutations = create((set) => ({
    
  setViewRecovery: (payload: boolean) => {
    loginState.setState({
      viewRecovery: payload,
    });
  },

  setLoadingSignIn: (payload: boolean) => {
    loginState.setState({
      loadingSignIn: payload,
    });
  },

  setViewTwoFactor: (payload: boolean) => {
    loginState.setState({
      viewTwoFactor: payload,
    });
  },

  setTemporalUser: (payload: any) => {
    loginState.setState({
      temporalUser: payload,
    });
  },

  setLoadingRequestOtp: (payload: boolean) => {
      loginState.setState({
        loadingRequestOtp: payload
      });
  }, 
  
  setTokenAuth: (payload: string) => {
      loginState.setState({
        tokenAuth: payload
      });
  }, 

  logout: () => {
    setUserMutation.logoutUser(null);
    removeItemLocal(SAVE_AUTH_LOCAL_STORAGE)
    window.location.href = "/login";
  }, 

  updatePassword: (payload: any) => {
    const newPassword = loginActions.getState();
    const {passwordFirst,passwordSecond,token} =  payload;
    const data:any = jwt_decode(token)
    const email = data.email
    if (passwordFirst === passwordSecond) {     
      newPassword.sendingNewPassword({password:passwordFirst,token,email})     
    } else {
      const data = {
        // code: 200,
        title: "Advertencia!",
        message: "Las contraseñas no coinciden.",
        alertType: "modal",
      };
      setTimeout(() => {
        setAlertsMutation.setInfoAlert(data);        
      }, 5000);
    }   
    
  },

  setRecoveryEmail:(payload: string)=>{
    loginState.setState({
      recoveryEmail: payload
    });
  },

  setOtpAttempts: (payload: number) => {
    loginState.setState({
      otpAttempts: payload,
    });
  },

  maxUseFromOTP: () =>{
    const setResponse = {
      title: "Intentos máximos alcanzados!",
      message: "Ha superado la cantidad de intentos permitidos. Por favor inicie el proceso nuevamente...",
      alertType: "modal",
      type: "error",
    }
    // loginMutations.getState().setOpenOtp(false);
    setAlertsMutation.setInfoAlert(setResponse);
    loginMutations.getState().setOtpAttempts(0)
    loginMutations.getState().logout()
  },
  setLoadingPage: (payload:{status:boolean;type?:IFLoaderPageType['type']}) => {
    loginState.setState({
      loadingPage: payload,
    });
  },
  setPendingRefreshToken: (payload: boolean) => {
    loginState.setState({
      pendingRefreshToken: payload,
    });
  },
}));
