// React import
import { useState } from "react"
// I18next 
import { useTranslation} from "react-i18next";
// Router
import { useParams } from "react-router-dom";
// Import element
import { ButtonClassic, FormClassic } from "../../elements/_elements.main";
// Store
import {loginMutations} from "../../store/login/_login.main"
// Import component
import { InputPassword } from "../../elements/_elements.main";


const LoginChangePassword = () => {
  // I18Next
  const [t,i18n] = useTranslation("change")
  const {updatePassword} = loginMutations()
  const urll = window.location.origin;

  // const [authToken, setAuthToken] = useState()
  const [disableBtn, setDisableBtn] = useState(true)

  const params:any = useParams();
  const { data } = params
  const token = data
  const [passwordFirst, setPasswordFirst] = useState("")
  const [passwordSecond, setPasswordSecond] = useState("")

  return (
    <>
      <div className="change-password">
        <div className="change-password__form">
          <p className="change-password__title">{t("CHANGE.CHANGE_TITLE")}</p>
          <p className="change-password__text">{t("CHANGE.CHANGE_PARAGRAPH")}</p>
          <FormClassic onSubmit={() => updatePassword({passwordFirst,passwordSecond,token})}>
            <InputPassword   
              width="w-full"            
              classes="my-2 mx-auto text-black"
              value={passwordFirst}
              placeholder={t("CHANGE.INPUT_PASSWORD")}
              onChange={(e) => setPasswordFirst(e.target.value)}
            />
            <InputPassword                
              classes="my-2 mx-auto text-black"
              width="w-full"
              value={passwordSecond}
              placeholder={t("CHANGE.INPUT_PASSWORD_CONFIRM")}
              onChange={(e) => setPasswordSecond(e.target.value)}
            />
            <ButtonClassic
              width="w-full"
              type="submit"
              classes="block mx-auto"
              onClick={() => {}}
              text={t("CHANGE.BUTTON")}
            />
          </FormClassic>
        </div>
      </div>
    </>
  )
}
export { LoginChangePassword }
