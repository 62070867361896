//Types import
import { BUTTON_OPTIONS } from "../types/types.dashboards";
//Components import
import { DashboardResume } from "../components/dashboard/DashboardResume";
import { DashboardTable } from "../components/dashboard/DashboardTable";
import { FloatingActionButtons } from "../elements/_elements.main";
//Stores import
import { consumersState ,consumersMutations} from "../store/consumers/_consumers.main";
//Assets import
import bar_chart from '../assets/icons/bar_chart.svg'
import person_pin from '../assets/icons/person_pin.svg'

export const Dashboard = () => {

  const {currentOption} = consumersState();
  const {setCurrentOption} = consumersMutations();
 
  const options = [
    <DashboardResume/>,
    <DashboardTable/>,
    <p>Demo page</p>,
  ]

  const handleClick = (payload:BUTTON_OPTIONS) => {
    setCurrentOption(payload);
  }
  
  const buttons:any = [
    {
      icon: bar_chart,
      value: 0
    },
    {
      icon: person_pin,
      value: 1
    },
    /* {
      icon: code,
      value: 2
    }, */
  ]
  return (
    <>
      <div id="container-dashboard" className="cover cover--relative pl-28 pr-8">
        <FloatingActionButtons buttons={ buttons } onClick={ handleClick } active={ currentOption }/>
        {options[currentOption]}
      </div>
    </>
  );
}
