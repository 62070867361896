// Axios import
import axios from "axios";

// Utils import
import { getConfig } from "./utils.headers";

//Store import
import {
	loginActions,
	loginState,
} from "../store/login/_login.main";

export const axiosInterceptor = axios.create();

axiosInterceptor.interceptors.request.use(
	async (config) => {
		config.headers = (await getConfig()).headers;
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

axiosInterceptor.interceptors.response.use(
	async (response) => {
		return response;
	},
	async function (error) {
		const setLoginActions = loginActions.getState();
		const { pendingRefreshToken } = loginState.getState();
		const originalRequest = error.config;

		if (error.response.status === 401 && !originalRequest._retry) {
			if (!pendingRefreshToken) {
				const access_token = await setLoginActions.refreshAccessToken();
				axios.defaults.headers.common["Authorization"] =
					"Bearer " + access_token;
			}
			return axiosInterceptor(originalRequest);
		}
		return Promise.reject(error);
	}
);
