import create from "zustand";
// Import main store
import { dashboardState } from "./_dashboard.main";

export const dashboardMutations = create((set)=>({
  setShowImage: (payload: boolean) => {
    dashboardState.setState({
     showImage: payload,
    });
   },

   setDataTable: (payload: any) => {
    dashboardState.setState({
      dataTable: payload,
     });
   },

   setTotalEvents: (payload: any) => {
    dashboardState.setState({
      totalEvents: payload,
     });
   },   

   setOriginalData: (payload: any) => {
    dashboardState.setState({
      originalData: payload,
     });
   },
   setEvents: (payload: any) => {
    dashboardState.setState({
      events: payload,
     });
   },
   
   setPageNumber: (payload: any) => {
    dashboardState.setState({
      pageNumber: payload,
     });
   },
   
   setRowsOfPage: (payload: any) => {
    dashboardState.setState({
      rowsOfPage: payload,
     });
   },
   
   setEventTypeList: (payload: any) => {
    dashboardState.setState({
      eventTypeList: payload,
     });
   },
   
  setProcessTypeList: (payload: any) => {
    dashboardState.setState({
      processTypeList: payload,
    });
  },

  setDevicesList: (payload: any) => {
    dashboardState.setState({
      devicesList: payload,
    });
  },

  setModalImageUser: (payload: boolean) => {
    dashboardState.setState({
      modalImageUser: payload,
     });
  },
  //resumen
  setSummaryCardData:(payload:any)=>{
    dashboardState.setState({
      summaryCardData: payload,
     });
  },
  setChartTypeList:(payload:string[])=>{
    dashboardState.setState({
      chartTypeList: payload,
    });
  },
  setSummaryChartData:(payload:any)=>{
    dashboardState.setState({
      summaryChartData: payload,
     });
  },
  setViewChart:(payload:string)=>{
    dashboardState.setState({
      viewChart: payload,
     });
  },
  setUsersChartData:(payload:any)=>{
    dashboardState.setState({
      usersChartData: payload,
     });
  },
  setProcessChartData:(payload:any)=>{
    dashboardState.setState({
      processChartData: payload,
     });
  },
  setCompletedChartData:(payload:any)=>{
    dashboardState.setState({
      completedChartData: payload,
     });
  },
  setErrorsChartData:(payload:any)=>{
    dashboardState.setState({
      errorsChartData: payload,
     });
  },
  setSpoofingChartData:(payload:any)=>{
    dashboardState.setState({
      spoofingChartData: payload,
     });
  },
  //busqueda
  setLoadingButtonCharts:(payload:any)=>{
    dashboardState.setState({
      loadingButtonCharts: payload,
     });
  },
  
  setLoadingButtonFilter:(payload:any)=>{
    dashboardState.setState({
      loadingButtonFilter: payload,
     });
  },

  setTableData:(payload:any)=>{
    dashboardState.setState({
      tableData: payload,
     });
  },

  setSearchData:(payload:any)=>{
    dashboardState.setState({
      searchData: payload,
     });
  },

  setUserDataModel: (payload:any) => {
    dashboardState.setState({
      userDataModel: payload,
     });
  },

  setCardDocument: (payload:any) => {
    dashboardState.setState({
      cardDocument: payload,
     });
  },

  setCardRecognition: (payload:any) => {
    dashboardState.setState({
      cardRecognition: payload,
     });
  },
  
  setCardValidation: (payload:any) => {
    dashboardState.setState({
      cardValidation: payload,
     });
  },
  
  setSearchInEvents: (payload:any) => {
    dashboardState.setState({
      searchInEvents: payload,
    });
  },
  setCities: (payload:any) => {
    dashboardState.setState({
      cities: payload,
    });
  },
  setDepartments: (payload:any) => {
    dashboardState.setState({
      departments: payload,
    });
  },
  setCountries: (payload:any) => {
    dashboardState.setState({
      countries: payload,
    });
  },

}));