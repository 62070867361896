import create from "zustand";
// Utils import
import { restPost, restPostNoEncrypt, getTokenAuth } from "../../utils/utils.rest";
import { setItemLocal, getItemLocal } from "../../utils/utils.storage"
import { dataDecrypt } from "../../utils/utils.encrypt-decrypt";
// Import store
import { userMutations, userState } from "../user/_user.main";
import { alertsMutations } from "../alerts/_alerts.main";
import { loginState, loginMutations } from "./_login.main";
// EndPoints import
import { URL_AUTH_LOGIN, SAVE_AUTH_LOCAL_STORAGE, URL_AUTH_OTP_GENERATE, URL_AUTH_OTP_VALIDATE, URL_AUTH_OTP_REGENERATE, URL_AUT_RES_PASS, URL_AUT_CHANG_PASS, URL_REFRESH_TOKEN } from "../../app.tickets";

//Constant
const setAlertsMutation = alertsMutations.getState();
const setLoginMutation = loginMutations.getState();
const setUserMutation = userMutations.getState();

export const loginActions = create(() => ({
  signIn: async (payload: {email:string, password:string}) => {
    setLoginMutation.setLoadingSignIn(true);
    try {
      const res = await restPostNoEncrypt({ url: URL_AUTH_LOGIN, data: payload });
      const tokenAuth = await res.data.data;
      const { token, refreshToken, authenticationScheme } = tokenAuth

      setItemLocal(SAVE_AUTH_LOCAL_STORAGE, {
        token: token,
        refreshToken: refreshToken,
        authenticationScheme: authenticationScheme
      })
    

      const temporalUser = loginState.getState().temporalUser;
      setLoginMutation.setTemporalUser({...temporalUser, email: payload.email});
      loginActions.getState().generateOTP()

    } catch (e: any) {
      const response = e.response;
      const data = {
        code: response.status,
        title: response.statusText,
        message: response.data.message,
        alertType: "modal",
      };
      setAlertsMutation.setInfoAlert(data);
      setLoginMutation.setLoadingSignIn(false);
    }
  },

  validateTwoFactor: async (payload: any) => {
    setLoginMutation.setLoadingRequestOtp(true);
    setLoginMutation.setLoadingPage({ status: true });
    try {

      const temporalUser = loginState.getState().temporalUser;

      const dataToValidateOTP = {
        TransactionId: temporalUser?.transactionId,
        OtpCode: payload,
      };

      const res = await restPost({ url: URL_AUTH_OTP_VALIDATE, data: dataToValidateOTP });
      const dataUser = await res.data.data;

      const isLogged = {
        ...dataUser,
        email: temporalUser?.email,
        logged: res.data.response
      };
      
      setUserMutation.setUser(isLogged);
      window.location.href = "/";
    } catch (e: any) {
      const response = e.response;
      const data = {
        code: response.status,
        title: response.statusText,
        message: response.data.message,
        alertType: "toast",
      };
      setAlertsMutation.setInfoAlert(data);
    }finally{
      setLoginMutation.setLoadingRequestOtp(false);
      setTimeout(() => {
        setLoginMutation.setLoadingPage({ status: false });
      }, 3000);
    }
  },

  recoveryPassword: async (payload: any) => {
    if (payload) {
      try {
        const res = await restPost({url:URL_AUT_RES_PASS,data:payload});
        const data = {
          code: 200,
          title: "Operación realizada con éxito",
          message: "La solicitud fue enviada a tu correo.",
          alertType: "toast",
        };
        setAlertsMutation.setInfoAlert(data);     
      } catch (error) {
        const data = {
          // code: 400,
          title: "Atención!",
          message: "Tu correo no estas registrado, por favor comunícate con el administrador.",
          alertType: "toast",
        };
        setAlertsMutation.setInfoAlert(data);
        
      }
    }
    setTimeout(() => {      
      setLoginMutation.setViewRecovery(false)      
    },5000);    
  },

  generateOTP: async () => {
    try {
      const temporalUser = loginState.getState().temporalUser;
      const res = await restPost({url:URL_AUTH_OTP_GENERATE,data:{emailTo: temporalUser?.email}});
      
      const auth = await res.data.response;
      const setData = {...res.data.data, email: temporalUser?.email}

      setLoginMutation.setTemporalUser(setData);

      setLoginMutation.setViewTwoFactor(auth);

      setLoginMutation.setLoadingSignIn(false);
    } catch (error) {
      
    } finally{
      setLoginMutation.setLoadingSignIn(false);
    }
  },

  requestOtp: async (payload:any) => {
    setLoginMutation.setLoadingRequestOtp(true);
    try {

      const dataToRegenerateOTP = {
        TransactionId: payload.transactionId,
        EmailTo: payload.email,
        Language: payload.language,
      };

      const res = await restPost({url: URL_AUTH_OTP_REGENERATE, data: dataToRegenerateOTP});

      const { expirationDate, transactionId } = res.data.data;
      
      const data = {
        code: 200,
        title: "Mensaje enviado!",
        message: "Hemos enviado un nuevo código",
        alertType: "toast",
      };
      setAlertsMutation.setInfoAlert(data);

      const userData = {
        ...payload,
        expirationDate: expirationDate,
        transactionId: transactionId,
      };

      setLoginMutation.setTemporalUser(userData);

    } catch (e: any) {
      const response = e.response;
      const data = {
        code: response.status,
        title: response.statusText,
        message: response.data.message,
        alertType: "toast",
      };
      setAlertsMutation.setInfoAlert(data);
    } finally {
      setLoginMutation.setLoadingRequestOtp(false);
    }
  },

  sendingNewPassword:async (payload: any) => {

    try {
      const res = await restPost({url:URL_AUT_CHANG_PASS,data:payload});
      const data = {
        code: "200",
        title: "Correcto!",
        message: "Contraseña se actualizo correctamente",
        alertType: "toast",
      };
      setAlertsMutation.setInfoAlert(data);
      setTimeout(() => {
        window.location.href = "/login";        
      }, 2500);
    } catch (error) {
      const data = {
        // code: "200",
        title: "Error!",
        message: "falla en el servidor.",
        alertType: "toast",
      };
      setAlertsMutation.setInfoAlert(data);
    }
   
  },

  refreshAccessToken: async () => {

    try {
        const user = userState.getState().user
        const userInfo:any = user && dataDecrypt(user);
        const getTokenInfo = await getItemLocal(SAVE_AUTH_LOCAL_STORAGE)
        const {email} = userInfo       
        const {setPendingRefreshToken}=loginMutations.getState()
        
        const {refreshToken, token, authenticationScheme} = getTokenInfo ?? {}
        
        const setData = {
          email,
          refreshToken,
          token,
          authenticationScheme
        }
       
        setPendingRefreshToken(true)
        const resp = await getTokenAuth({url:URL_REFRESH_TOKEN, data: setData});
       
        if(resp.status === 200){
          const { token, refreshToken, expirationDate, authenticationScheme } = resp.data.data
          
          setItemLocal(SAVE_AUTH_LOCAL_STORAGE, {
            token,
            refreshToken,
            expirationDate,
            authenticationScheme,
          })
          setPendingRefreshToken(false)
          return token
        }else{
          setLoginMutation.logout()
        }
    } catch (error:any) {

      setLoginMutation.logout()

      const data = {
        code: 400,
        title: 'Su sesión expiró',
        message: 'Por favor ingrese nuevamente...',
        alertType: "modal",
      };
      setAlertsMutation.setInfoAlert(data);

    }
}

}));
