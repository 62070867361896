// Axios import
import axios from "axios";
// Utils import
import { axiosInterceptor } from "./utils.interceptors";
import { dataEncrypt, dataDecrypt } from "./utils.encrypt-decrypt";

//Store import
import { userState } from "../store/user/_user.main";

export const restPost = async (payload: any) => {
  const data = await dataEncrypt(payload.data);  
  const query = JSON.stringify({ dataEncrypt: data });
  return axiosInterceptor.post(payload.url, query);
};

export const restPostNoEncrypt = async (payload: any) => {
  // const data = await dataEncrypt(payload.data);  
  const query = JSON.stringify(payload.data);
  return axiosInterceptor.post(payload.url, query);
};

export const restGet = async (payload: any) => {
  return axiosInterceptor.get(payload.url);
};

export const restPut = async (payload: any) => {
  const data = await dataEncrypt(payload.data);
  const query = JSON.stringify({ dataEncrypt: data });
  return axiosInterceptor.put(payload.url, query);
};

export const getTokenAuth = async (payload: any) => {
  const data = await dataEncrypt(payload.data)
  const query = JSON.stringify({ dataEncrypt: data })
  return axios.post(payload.url, query, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};