export const validateEmail = (email: string): boolean =>
 email && /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g.test(email) ? true : false;

export const validatePassword = (payload: string) => {
    let approve = payload.length > 6 &&
    RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{4,})").test(payload)
    ? true
    : false;
    return approve
}

export const focusReturn = (payload: any) => {
    if(payload.current && payload.current.value){
        payload.current.focus();
        let text = payload.current.value;
        payload.current.value = "";
        payload.current.value = text;
    }
}

export const handleBlur = (payload:any) => {
    payload.current.addEventListener("blur",(e:any)=>{
        let type = e.target.type
        
        //TODO: Validate best option for text type
        if(type === 'password' || type === 'text'){
            if(!validatePassword(e.target.value)){
                payload.current.classList.add("input__classic--error")
            }else{
                payload.current.classList.remove("input__classic--error")
            }
        }
       
        if(type === 'email'){
            if(!validateEmail(e.target.value)){
                payload.current.classList.add("input__classic--error")
            }else{
                payload.current.classList.remove("input__classic--error")
            }
        }
    })
};

export const inputComplete = (payload:any) => {
  if (payload.value) {
    return `input__classic__${payload.color}--complet`
  } else {
    return  ""
  }
};

export const blurEffect = (payload:string) => {
   return payload !== "" ? "bg-white" : "";
}