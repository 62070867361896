import React from "react";
// Import router
import { Routes, Route, Navigate } from "react-router-dom";
// Import components
import { LoginChangePassword } from "../components/login/LoginChangePassword";
import { Login } from "../views/Login";

export const RoutesPublic: React.FC<any> = ({ children }) => {
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/changepassword/:data" element={<LoginChangePassword />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </>
  );
};
