import { useEffect, useRef, useState } from 'react';
// Styles import
import "../../assets/styles/elements/elements.inputs.css";

interface IFInputSwitch{
    classes: string;
    size: | 'small' | 'standard' | 'big' ;
    colorEnable: string;
    colorDisable: string;
    disabled: boolean;
    checked: boolean;
    onChange(e: any): void;
};
const defaultProps = {
    classes: "",
    colorEnable: "bg-primary",
    colorDisable: "bg-gray-200",
    size: 'standard',
    disabled: false,
    checked: false,
    onChange: null
};

export const InputSwitch = (props:IFInputSwitch) => {
  
  const [colorSwitch, setColorSwitch] = useState(props.checked ? props.colorEnable : props.colorDisable);
  const inputElement = useRef(null);  
  const size:any = {
    small: 'h-3 w-6',
    standard: 'h-5 w-10',
    big: 'h-7 w-14',
  };

  const circle:any = {
    small: 'h-3 w-3',
    standard: 'h-5 w-5',
    big: 'h-7 w-7',
  };

  const drawButton = ()=>{
    return circle[props.size]
  };

  const resize = ()=>{
    return size[props.size]
  };

  useEffect(()=>{
    inputElement.current && handleChange(inputElement.current)
  },[colorSwitch]);

  const handleChange = (input:any) => {
    input.addEventListener("change",(e:any)=>{
        const colorEnable = e.target.checked ? props.colorEnable : props.colorDisable
        setColorSwitch(colorEnable)
    })
  };

  return(
    <>
      <label className={`switch ${resize()} ${props.classes} ${colorSwitch} `}>
        <input 
          ref={ inputElement }
          type="checkbox"            
          className="switch__input"
          onChange={ props.onChange }
          checked={ props.checked }
          value = ''
        />
        <div className={`switch__button ${drawButton()}`}></div>
      </label>
    </>
    );
}

InputSwitch.defaultProps = defaultProps;
