import React, { useEffect, useRef } from 'react'

// External libraries import
import { useTranslation } from "react-i18next";

// Components import
import { ButtonClassic, FormClassic, InputClassic, InputDate, SelectClassic } from '../../elements/_elements.main'

// Store import
import { dashboardState, dashboardMutations, dashboardAction } from "../../store/dashboard/_dashboard.main";

//Utils import
import { mapTableFilter } from "../../utils/utils.map";

interface IFDashboardFilter {
    handleSubmit(e:any):void;
}

const defaultProps = {
    handleSubmit:null
}

export const DashboardFilter = (props:IFDashboardFilter) => {
    
    const [t, i18n] = useTranslation("dashboard");

    const btnSeearchRef = useRef<HTMLButtonElement>(null)
   
    const { eventTypeList, processTypeList, devicesList, loadingButtonFilter, searchInEvents,cities,departments,countries} = dashboardState()
    const { setSearchInEvents } = dashboardMutations()
    const { getEventTypeList, getProcessTypeList, getDevicesList,getCities,getDepartments,getCountries } = dashboardAction()
    
    
    const handleClickClean = ()=>{
        setSearchInEvents(null)
        let form:any = document.querySelector("#filter")
        form.reset()
    }

    const handleSubmit = () => {
        let dataToSearch = mapTableFilter(searchInEvents!)        
        props.handleSubmit(dataToSearch)
    }

    useEffect(()=>{
        btnSeearchRef.current && btnSeearchRef.current.click()
        getEventTypeList(t)
        getProcessTypeList(t)
        getDevicesList(t)
        getCities(t)
        getCountries(t)
        getDepartments(t)
    },[])

return (
    <>
        <div>
            <FormClassic id="filter" onSubmit={ handleSubmit } classes="dashboard-filter__form">
                <div>
                    <label className="dashboard-filter__label">
                        {t("DASHBOARD_EVENTS.EVENT_ID")}:
                    </label>
                    <InputClassic
                        placeholder={t("DASHBOARD_EVENTS.SEARCH_BY_EVENT_ID")}
                        height="h-10"
                        value={ searchInEvents?.eventID }
                        required={ false }
                        onChange={(e)=>{setSearchInEvents({...searchInEvents!, eventID: e.target.value.trim()})}}
                    />
                </div>
                <div>
                    <label className="dashboard-filter__label">
                        {t("DASHBOARD_EVENTS.USER_ID")}:
                    </label>
                    <InputClassic
                        placeholder={t("DASHBOARD_EVENTS.SEARCH_BY_USER_ID")}
                        height="h-10"
                        width='w-56'
                        value={ searchInEvents?.userID }
                        required={ false }
                        onChange={(e)=>{setSearchInEvents({...searchInEvents!, userID: e.target.value.trim()})}}
                    />
                </div>
                <div className='flex gap-3'>
                    <div>
                        <label className="dashboard-filter__label">
                            {t("DASHBOARD_EVENTS.START_DATE")}:
                        </label>
                        <InputDate name="startDate" value={ searchInEvents?.startDate } onChange={(e)=>{setSearchInEvents({...searchInEvents!, startDate: e.target.value})}} required={ false }/>
                    </div>
                    <div>
                        <label className="dashboard-filter__label">
                            {t("DASHBOARD_EVENTS.END_DATE")}:
                        </label>
                        <InputDate name="endDate" value={ searchInEvents?.endDate } onChange={(e)=>{setSearchInEvents({...searchInEvents!, endDate: e.target.value})}} required={ false }/>
                    </div>
                </div>
                <div>
                    <label className="dashboard-filter__label">Procesos:</label>
                    <SelectClassic name="process" classes='dashboard-filter__select' onChange={(e)=>{setSearchInEvents({...searchInEvents!, process: e.target.value})}} options={processTypeList} />
                </div>
                <div>
                    <label className="dashboard-filter__label">
                        {t("DASHBOARD_EVENTS.TYPE")}:
                    </label>
                    <SelectClassic name="types" classes='dashboard-filter__select' onChange={(e)=>{setSearchInEvents({...searchInEvents!, types: e.target.value})}} options={eventTypeList} />
                </div>
                <div>
                    <label className="dashboard-filter__label">
                        {t("DASHBOARD_EVENTS.DEVICE")}:
                    </label>
                    <SelectClassic name="device" classes='dashboard-filter__select' onChange={(e)=>{setSearchInEvents({...searchInEvents!, devices: e.target.value})}} options={ devicesList } />
                </div>
                <div>
                    <label className="dashboard-filter__label">
                        {t("DASHBOARD_EVENTS.COUNTRY")}:
                    </label>
                    <SelectClassic name="country" classes='dashboard-filter__select' onChange={(e)=>{setSearchInEvents({...searchInEvents!, country: e.target.value})}} options={ countries } />
                </div>
                <div>
                    <label className="dashboard-filter__label">
                        {t("DASHBOARD_EVENTS.STATE")}:
                    </label>
                    <SelectClassic name="state" classes='dashboard-filter__select' onChange={(e)=>{setSearchInEvents({...searchInEvents!, department: e.target.value})}} options={ departments } />
                </div>
                <div>
                    <label className="dashboard-filter__label">
                        {t("DASHBOARD_EVENTS.CITY")}:
                    </label>
                    <SelectClassic name="city" classes='dashboard-filter__select' onChange={(e)=>{setSearchInEvents({...searchInEvents!, city: e.target.value})}} options={ cities } />
                </div>
                <div className='ml-auto'>
                    <ButtonClassic
                        type="button"
                        width=""
                        color="error"
                        height="h-10"
                        text={t("DASHBOARD_EVENTS.CLEAN")}
                        onClick={ handleClickClean }
                        classes='mr-2'
                    />
                    <ButtonClassic
                        ref={ btnSeearchRef }
                        type="submit"
                        width="w-28"
                        height="h-10"
                        loading={ loadingButtonFilter }
                        text={t("DASHBOARD_EVENTS.SEARCH")}
                        onClick={()=>{}}
                    />
                </div>
            </FormClassic>
        </div>
    </>
    )
}

DashboardFilter.defaultProps = defaultProps