// React import
import { useEffect, useState } from "react";
// Elements Import
import {
	InputClassic,
	CardConsumer,
	FormClassic,
} from "../../elements/_elements.main";
import { SkeletonsCardConsumer } from "../../elements/skeletons/SkeletonsCardConsumer";
//Types import
import { BUTTON_OPTIONS, COLORS_TYPE } from "../../types/types.dashboards";

// Stores import
import {
	consumersState,
	consumersMutations,
	consumersActions,
} from "../../store/consumers/_consumers.main";
import {
	dashboardState,
	dashboardMutations,
	dashboardAction,
} from "../../store/dashboard/_dashboard.main";
// Images import
import noResult from "../../assets/img/imgNoResult.png";
// Icons import
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
// React router
import { useNavigate } from "react-router-dom";

//Utils import
import { mapTableFilter } from "../../utils/utils.map";
import { useTranslation } from "react-i18next";

export const AppSidebarSearch = () => {
	//
	const navigate = useNavigate();

	//i18next
	const [t, i18n] = useTranslation("appSidebar");
	//Data
	const [search, setSearch] = useState("");
	// Store
	const { pageNumber, rowsOfPage, searchInEvents } = dashboardState();
	const { setPageNumber, setSearchInEvents } = dashboardMutations();
	const { setCurrentOption } = consumersMutations();
	const { getInitialConsumers, getConsumers } = consumersActions();
	const { loadTable } = dashboardAction();
	const { loadingGetConsumers, consumers } = consumersState();
	const [showBar, setShowBar] = useState(false);

	const status = () => (loadingGetConsumers ? 1 : consumers?.length ? 2 : 3);

	useEffect(() => {
		search.length === 0 && getInitialConsumers();
	}, [search]);

	const getUserEvents = (payload: any) => {
		const url = window.location.pathname;
		if (url !== "/") {
			navigate(`/dashboard`);
		}

		setCurrentOption(BUTTON_OPTIONS.EVENTS);

		setSearchInEvents({ ...searchInEvents, userID: payload.userAppId });

		let dataToSearch = {
			...mapTableFilter({ ...searchInEvents!, userID: payload.userAppId }),
			PageNumber: pageNumber,
			RowsOfPage: rowsOfPage,
		};
		loadTable(dataToSearch);
	};
	const showSearchBar = (value: boolean) => {
		const container: any = document.getElementById("container-dashboard");
		if (value) {
			container.classList.add("app-main-container");
		} else {
			container.classList.remove("app-main-container");
		}
		setShowBar(value);
	};
	window.addEventListener("click", function (e: any) {
		const divContainer: any = document.getElementById("searchBar");
		let show=false
		const classes=[... e.target.classList]		
		const haveClass=classes?.find((classTemp:string)=>classTemp.includes('card-consumer'))
		if(haveClass){
			show=false
		}else if(divContainer?.contains(e.target)){
			show=true
		}
		showSearchBar(show)
		
	});

	return (
		<>
			<div id="searchBar" className="app-sidebar-search">
				<div
					className={`app-sidebar-search__navbar ${
						showBar
							? "animate-fadeIn app-sidebar-search__navbar--focused"
							: "-top-[17px]"
					}`}
				>
					<FormClassic onSubmit={() => getConsumers(search)} classes="w-full">
						<InputClassic
							classes="mt-4"
							icon={faMagnifyingGlass}
							placeholder={`${t("TEXT_LABEL_FIND_USER")}`}
							width="w-52"
							height="h-10"
							value={search}
							onChange={(e) => {
								setSearch(e.target.value);
							}}
							onClickIcon={() => getConsumers(search)}
						/>
					</FormClassic>
				</div>
				<div
					className={
						showBar ? "app-sidebar-search__content animate-fadeIn" : "hidden"
					}
				>
					{status() === 2 &&
						consumers?.map((item: any, i: number) => (
							<CardConsumer
								key={i}
								img={item.lastImgOk}
								title={item.userAppId}
								description={item.dateLastImgOk}
								color={(COLORS_TYPE as any)[item.eventType]}
								onClick={() => {
									getUserEvents(item);
								}}
							/>
						))}

					{status() === 1 &&
						[...Array(5)].map((item, i) => <SkeletonsCardConsumer key={i} />)}

					{status() === 3 && (
						<div className="app-sidebar-search__no-result">
							<div className="text-center">
								<img
									src={noResult}
									alt="noResult"
									className="app-sidebar-search__img-no-result"
								/>
								<h3 className="text-gray-400">Sin resultados</h3>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};
