// Images imports
import bgAboutCover from "../assets/img/bgAboutCover.png";
// Components imports
import { LoginSignIn } from "../components/login/LoginSignIn";
import { LoginTwoFactor } from "../components/login/LoginTwoFactor";
import { LoginRecovery } from "../components/login/LoginRecovery";
//Store import
import { loginState } from "../store/login/_login.main";

export const Login = () => {
    // Constant
    const { viewTwoFactor, viewRecovery } = loginState();
    return (
        <>
            <div className="login login--relative">
                <img src={bgAboutCover} alt="bgAboutCover" className="login__image-background" />
                <div className="login__forms">
                    {viewTwoFactor ? <LoginTwoFactor /> : <LoginSignIn />}
                </div>
                {viewRecovery && <LoginRecovery />}
            </div>
        </>
    );
};
