import React from 'react';
//Components import
import { ButtonIcon } from '../../elements/_elements.main';

interface IFFloatingActionButtons {
    buttons:[];
    position: 'left' | 'right'
    active:number;
    onClick: (e:any) => void;
}

const defaultProps = {
    buttons: [],
    position: 'left',
    active: 0,
    onClick: null,
}

export const FloatingActionButtons = (props:IFFloatingActionButtons) => {

    const position = (payload:string) => {
        let location:any = {
            left: "-left-4",
            right: "-right-4",
        }
        return location[payload]
    }

  return (
      <>
        <div className={`floating ${position(props.position)}`}>
            <div className="floating__buttons">
                {
                    props.buttons.map((button:any, index:any)=>(
                        <ButtonIcon
                        key={ index }
                        width="w-10"
                        height="h-10"
                        classes={`floating__button ${index === props.active ? 'floating__button--active' : ''}`}
                        icon={ button.icon }
                        onClick={ ()=> props.onClick(button.value) }
                        />
                    ))
                }                
            </div>
        </div>
      </>
  )
}

FloatingActionButtons.defaultProps = defaultProps