import React from 'react'

export const EllipsisLoader = () => {
  return (
    <>
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
    </>
  )
}
