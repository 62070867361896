//peticion externas
import create from "zustand";
import { restPost, restGet } from "../../utils/utils.rest";
// Store
import { dashboardMutations } from "./dashboard.Mutation";
import { alertsMutations } from "../alerts/_alerts.main";
import { loginMutations } from "../login/_login.main";

// EndPoints import
import {
	URL_SUMMARY_CARD_COUNT,
	URL_CHART_TYPE_LIST,
	URL_SUMMARY_CHART_COUNT,
	URL_GET_PAGINATED,
	URL_USERS_CHART,
	URL_PROCESS_CHART,
	URL_COMPLETED_CHART,
	URL_ERRORS_CHART,
	URL_SPOOFING_CHART,
	URL_GET_IMAGE,
	URL_GET_PROCESS_LIST,
	URL_GET_DEVICES_LIST,
	URL_GET_EVENTS_LIST,
	URL_GET_EVENTS_MENU_LIST,
	URL_ABANDONMENT,
	URL_COUNTRIES,
	URL_DEPARTMENTS,
	URL_CITIES,
} from "../../app.tickets";

const setAlertsMutation = alertsMutations.getState();
const setDashboardMutations = dashboardMutations.getState();
const setLoginMutations = loginMutations.getState();

export const dashboardAction = create(() => ({
	summaryCardQuery: async (payload: any) => {
		try {
			const res = await restPost({
				url: URL_SUMMARY_CARD_COUNT,
				data: payload,
			});
			const cardData = await res.data.chartTypes;
			setDashboardMutations.setSummaryCardData(cardData);
		} catch (error) {
			console.error(error);
		}
	},

	getChartTypeList: async () => {
		try {
			const res = await restGet({ url: URL_CHART_TYPE_LIST });			
			setDashboardMutations.setChartTypeList(res.data.data.chartTypes);
		} catch (error) {
			console.error(error);
		}
	},

	summaryChartQuery: async (payload: any) => {
		setDashboardMutations.setLoadingButtonCharts(true);
		setLoginMutations.setLoadingPage({ status: true });
		try {
			const res = await restPost({
				url: URL_SUMMARY_CHART_COUNT,
				data: payload,
			});
			const chartsList = await res.data.data;
			setDashboardMutations.setSummaryChartData(chartsList);
		} catch (err: any) {
			const response = err.response;
			const data = {
				code: response.status,
				title: response.statusText,
				message: response.data.message,
				alertType: "modal",
			};
			setAlertsMutation.setInfoAlert(data);
		} finally {
			setDashboardMutations.setLoadingButtonCharts(false);
			setTimeout(() => {
				setLoginMutations.setLoadingPage({ status: false });
			}, 1000);
		}
	},

	getUsersChart: async (payload: any) => {
		try {
			const res = await restPost({ url: URL_USERS_CHART, data: payload });
			const userChart = await res.data.data;
			setDashboardMutations.setUsersChartData(userChart);
		} catch (error) {
			console.error(error);
		}
	},

	getProcessChart: async (payload: any) => {
		try {
			const res = await restPost({ url: URL_PROCESS_CHART, data: payload });
			const processChart = await res.data.data;
			setDashboardMutations.setProcessChartData(processChart);
		} catch (error) {
			console.error(error);
		}
	},

	getCompletedChart: async (payload: any) => {
		try {
			const res = await restPost({ url: URL_COMPLETED_CHART, data: payload });
			const completedChart = await res.data.data;
			setDashboardMutations.setCompletedChartData(completedChart);
		} catch (error) {
			console.error(error);
		}
	},

	getErrorsChart: async (payload: any) => {
		try {
			const res = await restPost({ url: URL_ERRORS_CHART, data: payload });
			const errorsChart = await res.data.data;
			setDashboardMutations.setErrorsChartData(errorsChart);
		} catch (error) {
			console.error(error);
		}
	},

	getSpoofingChart: async (payload: any) => {
		try {
			const res = await restPost({ url: URL_SPOOFING_CHART, data: payload });
			const spoofingChart = await res.data.data;
			setDashboardMutations.setSpoofingChartData(spoofingChart);
		} catch (error) {
			console.error(error);
		}
	},

	loadTable: async (payload: any) => {
		setLoginMutations.setLoadingPage({ status: true });
		setDashboardMutations.setLoadingButtonFilter(true);
		try {
			const res = await restPost({ url: URL_GET_PAGINATED, data: payload });
			const data = res.data.data;
			const totalEvents = parseInt(data.totalEvents);
			setDashboardMutations.setTotalEvents(totalEvents);
			setDashboardMutations.setEvents(
				JSON.parse(JSON.stringify(res.data.data.events))
			);
			setDashboardMutations.setOriginalData(
				JSON.parse(JSON.stringify(res.data.data.events))
			);
		} catch (error) {
			setDashboardMutations.setTotalEvents(0);
			setDashboardMutations.setEvents([]);
			setDashboardMutations.setOriginalData([]);
		} finally {
			setDashboardMutations.setLoadingButtonFilter(false);
			setTimeout(() => {
				setLoginMutations.setLoadingPage({ status: false });
			}, 1000);
		}
	},

	requestImage: async (payload: any) => {
		try {
			const res = await restPost({ url: URL_GET_IMAGE, data: payload });
			return res.data.data;
		} catch (error) {
			console.log(error);
		}
	},

	getEventTypeList: async (translation:any) => {
		try {
			const res = await restGet({ url: URL_GET_EVENTS_LIST });
			const temp:any=[{value:"",text:translation("DASHBOARD_EVENTS.EMPTY_SELECT")}]
			res.data.data.map((element: any) => {
				temp.push({value:element.id,text:element.name})
			});
			setDashboardMutations.setEventTypeList(temp);
		} catch (error) {
			console.log(error);
		}
	},

	getEventTypeMenuList: async (translation:any) => {
		try {
			const res = await restGet({ url: URL_GET_EVENTS_MENU_LIST });
			const temp:any=[{value:"",text:translation("DASHBOARD_EVENTS.EMPTY_SELECT")}]
			res.data.data.map((element: any) => {
				temp.push({value:element.id,text:element.name})
			});
			setDashboardMutations.setEventTypeList(temp);
		} catch (error) {
			console.log(error);
		}
	},

	getProcessTypeList: async (translation:any) => {
		try {
			const res = await restGet({ url: URL_GET_PROCESS_LIST });
			const temp:any=[{value:"",text:translation("DASHBOARD_EVENTS.EMPTY_SELECT")}]
			res.data.data.map((process: any) => {
				temp.push({value:process.id,text:process.name})
			});
			setDashboardMutations.setProcessTypeList(temp);
		} catch (error) {
			console.log(error);
		}
	},

	getDevicesList: async (translation:any) => {
		try {
			const res = await restGet({ url: URL_GET_DEVICES_LIST });
      const temp:any=[{value:"",text:translation("DASHBOARD_EVENTS.EMPTY_SELECT")}]
			res.data.data.map((device: any) => {
				device && temp.push({value:device,text:device})
			});
			setDashboardMutations.setDevicesList(temp);
		} catch (error) {
			console.log(error);
		}
	},

	getBowlAbandonment: async (payload: any) => {
		try {
			const res = await restPost({ url: URL_ABANDONMENT, data: payload });
			return res.data.data;
		} catch (err) {
			console.log(err);
		}
	},
	getCountries: async (translation:any) => {
		try {
			const res: any = await restGet({ url: URL_COUNTRIES });
			const temp:any=[{value:"",text:translation("DASHBOARD_EVENTS.EMPTY_SELECT")}]
			res.data.data.map((country: any) => {
				country && temp.push({value:country,text:country})
			});
      
			setDashboardMutations.setCountries(temp);
		} catch (error) {
			console.log(error);
		}
	},

	getDepartments: async (translation:any) => {
		try {
			const res = await restGet({ url: URL_DEPARTMENTS });
			const temp:any=[{value:"",text:translation("DASHBOARD_EVENTS.EMPTY_SELECT")}]
			res.data.data.map((department: any) => {
				department && temp.push({value:department,text:department})
			});
			setDashboardMutations.setDepartments(temp);
		} catch (error) {
			console.log(error);
		}
	},
	getCities: async (translation:any) => {
		try {
			const res = await restGet({ url: URL_CITIES });
			const temp:any=[{value:"",text:translation("DASHBOARD_EVENTS.EMPTY_SELECT")}]
			res.data.data.map((city: any) => {
				city && temp.push({value:city,text:city})
			});
			setDashboardMutations.setCities(temp);
		} catch (error) {
			console.log(error);
		}
	},
}));
