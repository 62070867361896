import React from 'react'
// Images import
import iconLoading from "../../assets/icons/loading.svg";
// Styles import
import "../../assets/styles/elements/elements.buttons.css";

//Props
interface IFButtonClassic {
  type?: "button" | "submit";
  text?: string;
  color?: string;
  height?: string;
  width?: string;
  classes?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
};

const defaultProps:Partial<IFButtonClassic> = {
  type: "button",
  text: "Button text",
  color: "primary",
  height: "h-12",
  width: "w-full md:w-72",
  classes: "",
  disabled: false,
  loading: false,
  onClick: ()=>{},
};

export const ButtonClassic = React.forwardRef<HTMLButtonElement, IFButtonClassic>((props:IFButtonClassic, ref:any) => {

  const color = () => {      
    const options: any = {
      primary: "btn-classic--primary",
      secondary: "btn-classic--secondary",
      error: "btn-classic--error",
    };
    return options[props.color!];
  };

  return (
    <>
      <button
        ref={ ref }
        type={props.type}
        disabled={props.disabled || props.loading}
        className={`
          btn-classic
          ${color()}
          ${props.width}
          ${props.height}
          ${props.classes}
        `}
        onClick={props.onClick}
      >
        {!props.loading ? (
          <span>{props.text}</span>
        ) : (
          <img
            src={iconLoading}
            alt="loading"
            className="btn-classic__loader"
          />
        )}
      </button>
    </>
  );
});

ButtonClassic.defaultProps = defaultProps;
