//I18Next Import
import { useTranslation } from "react-i18next";
//  Elements imports
import {
	ModalClassic,
	ButtonClassic,
	InputPinGenerator,
} from "../../elements/_elements.main";
// Store imports
import {
	loginState,
	loginActions,
	loginMutations,
} from "../../store/login/_login.main";
import { ButtonBack } from "../../elements/buttons/ButtonBack";

export const LoginTwoFactor = () => {
	// I18Next
	const [t, i18n] = useTranslation("login");
	//Get language
	const language = localStorage.getItem("language")?.toString();
	// State
	const { temporalUser, loadingRequestOtp, loadingResendOTP, otpAttempts } =
		loginState();
	// Actions
	const { validateTwoFactor, requestOtp } = loginActions();

	const { maxUseFromOTP, logout } = loginMutations();

	const length = 4;
	const totalAttemptsAuth = 4;

	const handleComplete = (payload: any) => {
		if (payload.length === length) {
			validateTwoFactor(payload);
		}
	};

	const handleResendOTP = () => {
		if (otpAttempts < totalAttemptsAuth) {
			requestOtp({ ...temporalUser, language });
		} else {
			maxUseFromOTP();
		}
	};

	return (
		<>
			<ModalClassic>
				<ButtonBack 
					tooltip={`${t("LOGIN_TWO_FACTOR.BUTTON_BACK")}`}
					onClick={() => {
						logout();
					}}
				/>
				<h3 className="login-two-factor__title">
					{t("LOGIN_TWO_FACTOR.TITLE")}
				</h3>
				<p className="login-two-factor__text--description">
					{t("LOGIN_TWO_FACTOR.PARAGRAPH")}
				</p>
				<div className="grid justify-items-center  ">
					<InputPinGenerator
						disabled={loadingResendOTP}
						length={length}
						width="w-11"
						height="h-11"
						clean={false}
						classes={`login-two-factor__text--input ${
							loadingResendOTP && "login-two-factor__text--input--disable"
						}`}
						onComplete={handleComplete}
					/>
				</div>
				<ButtonClassic
					loading={loadingRequestOtp}
					color="secondary"
					width="w-auto"
					text={t("LOGIN_TWO_FACTOR.BUTTON_OTP")}
					onClick={handleResendOTP}
					classes="mt-5"
				/>
			</ModalClassic>
		</>
	);
};
