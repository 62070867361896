import React from 'react'

//Types import
import { IFLoaderPageType } from "../../types/types.login";

// Components import
import { LoaderAtom } from "./LoaderAtom";

const defaultProps = {
    type: 'atom',
    text: 'Cargando...'
}

export const LoaderPageType = (props:IFLoaderPageType) => {

  const loaderType = {
      atom: <LoaderAtom text={ props.text }/>,
  }

  return (
    <>{ (loaderType as any)[props.type] }</>
  )
}

LoaderPageType.defaultProps = defaultProps
