import create from "zustand";
// Types imports
import { LoginState } from "../../types/types.login";

export const loginState = create<LoginState>(() => ({
  loadingSignIn: false,
  viewTwoFactor: false,
  viewRecovery: false,
  temporalUser: null,
  otpAttempts: 0,
  loadingRequestOtp: false,
  loadingResendOTP: false,
  recoveryEmail: null,
  tokenAuth:null,
  pendingRefreshToken:false,
  loadingPage:{
    status: false,
    type:"atom"
 },
}));
