import create from "zustand";
// Import main store
import { alertsState } from "./_alerts.main";

export const alertsMutations = create((set) => ({
    
  setInfoAlert: (payload: any) => {
    alertsState.setState({
      infoAlert: payload,
    });
    alertsMutations.getState().setShowAlert(true)
  },
  setShowAlert: (payload: boolean) => {
    alertsState.setState({
      showAlert: payload,
    });
  },
}));
