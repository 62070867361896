//Import Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
//Import element
import { InputSwitch } from "../_elements.main";
//Import react
import { useState } from "react";

export const InputSwitchChangeTheme = () => {
	//hook
	const [mode, setMode] = useState(true);
	const onChangeHandle = () => {
		document.body.classList.toggle("dark");
		setMode(!mode);
	};
	return (
		<>
			<div className="switch__container--mode">
				<FontAwesomeIcon
					className={`mt-[2px] ${!mode ? "text-yellow-300" : "text-white/80"}`}
					icon={faSun}
				/>
				<InputSwitch checked={mode} onChange={onChangeHandle} />
				<FontAwesomeIcon
					className={`mt-[2px] ${mode ? "text-yellow-300" : "text-white/80"}`}
					icon={faMoon}
				/>
			</div>
		</>
	);
};
