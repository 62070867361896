//Utils import
import { dataEncrypt, dataDecrypt } from "./utils.encrypt-decrypt";

export const setItemLocal = (name:string,data:any)=>{
    window.localStorage.setItem(name, dataEncrypt(data))
}

export const getItemLocal = async (payload:string) =>{
    return await dataDecrypt(window.localStorage.getItem(payload))
}

export const removeItemLocal = (payload:string) => {
    return window.localStorage.removeItem(payload)
}

export const removeAllItemsLocal = ()=>{
    return window.localStorage.clear()
}