//Router import
import { Routes, Route } from "react-router-dom";
//Routes
import { RoutesPublic } from "./routes.public";
import { RoutesPrivate } from "./routes.private";

export const RoutesMain = (props:any) => {

  return (
    <Routes>
      <Route path="/*" element={props.auth ? <RoutesPrivate /> : <RoutesPublic />} />
    </Routes>
  );
};
