import {  faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
interface IFButtonBack {
    tooltip?: string;
    color?: string;
    height?: string;
    width?: string;
    classes?: string;
    onClick: () => void;
  };
  
  const defaultProps:Partial<IFButtonBack> = {
    tooltip: "",
    color: "bg-primary",
    height: "h-6",
    width: "w-6",
    classes: "",
  };
export const ButtonBack=(props:IFButtonBack)=>{
    return(<>
    <div title={props.tooltip} onClick={props.onClick} className={`btn-back__container ${props.classes} ${props.height} ${props.width} ${props.color}`}>
        <FontAwesomeIcon  className="btn-back__icon" icon={faChevronLeft}/>
    </div>
    </>);
}
ButtonBack.defaultProps = defaultProps;