// React router
import { useLocation, useNavigate } from "react-router-dom";
// I18Next Import
import { useTranslation } from "react-i18next";
//Types import
import { BUTTON_OPTIONS } from "../../types/types.dashboards";
// Components import
import {
	ButtonClassic,
	ButtonText,
	ModalClassic,
} from "../../elements/_elements.main";
// Store import
import { loginMutations } from "../../store/login/login.mutations";
import { consumersMutations } from "../../store/consumers/_consumers.main";
// Images and icons import
import logoCognox from "../../assets/img/logoCognox.svg";

//Utils import
import { setItemLocal } from "../../utils/utils.storage";
import { AppSidebarSearch } from "./AppSidebarSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { InputSwitchChangeTheme } from "../../elements/inputs/InputSwitchChangeTheme";
import { useState } from "react";

export const AppNavbar = () => {
	// I18Next
	const [t, i18n] = useTranslation("app");
	// Router
	const location = useLocation();
	const navigate = useNavigate();
	// Store
	const { logout } = loginMutations();
	const { setCurrentOption } = consumersMutations();
	// Funtion
	const changeLanguage = (payload: string) => {
		i18n.changeLanguage(payload);
		setItemLocal("language", payload);
	};
	const [showModal, setShowModal] = useState<boolean>(false);

	const handleRedirect = () => {
		navigate(`/dashboard`);
		setCurrentOption(BUTTON_OPTIONS.INDICATORS);
	};

	return (
		<>
			<div className="app-navbar">
				<div className="app-navbar__content">
					<img src={logoCognox} alt="logoCognox" className="app-navbar__logo" />
					<div className="app-navbar__title">
						<ButtonText
							width="w-auto"
							text={t("NAVBAR.BUTTON_DASHBOARD")}
							onClick={handleRedirect}
							classes={`mr-3 font-thin ${
								location.pathname === "/dashboard" ? "font-bold" : ""
							}`}
						/>
					</div>
					<div className="ml-auto flex">
						<AppSidebarSearch />
						<div className="flex gap-3">
							<ButtonText
								height="h-10"
								width="w-24"
								text={"ENG"}
								onClick={() => changeLanguage("en")}
								classes={`${i18n.language === "en" && "!font-bold"} !w-full`}
							/>
							<ButtonText
								height="h-10"
								width="w-24"
								text={"ESP"}
								onClick={() => changeLanguage("es")}
								classes={`${i18n.language === "es" && "!font-bold"} !w-full`}
							/>
						</div>
						<InputSwitchChangeTheme />
						<FontAwesomeIcon
							title={t("NAVBAR.BUTTON_LOGOUT")}
							className="app-navbar__icon--close"
							onClick={() => {
								setShowModal(true);
							}}
							icon={faArrowRightFromBracket}
						/>
					</div>
				</div>
			</div>
			{showModal && (
				<ModalClassic>
					<div className="space-y-4">
						<h1 className="text-2xl">{t("NAVBAR.TITLE_CLOSE_SESSION")}</h1>
						<p>{t("NAVBAR.TEXT_CLOSE_SESSION")}</p>
						<div className="flex space-x-4">
							<ButtonClassic
								onClick={() => {
									logout();
									setShowModal(false);
								}}
								text={t("NAVBAR.BUTTON_CLOSE_YES")}
							/>
							<ButtonClassic
								color="secondary"
								onClick={() => setShowModal(false)}
								text={t("NAVBAR.BUTTON_CLOSE_NOT")}
							/>
						</div>
					</div>
				</ModalClassic>
			)}
		</>
	);
};
