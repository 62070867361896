import React from 'react'
interface IFLoaderAtom{
  text:string;
}

const defaultProps = {
  text: "Cargando..."
}

export const LoaderAtom = (props:IFLoaderAtom) => {
  return (
    <div className="loader">
      <p className="loader-atom__text">{ props.text }</p>
      <span className="loader-atom__spin"></span>
    </div>
  )
}

LoaderAtom.defaultProps = defaultProps
