// React
import { useState, useEffect } from "react";
// I18Next Import
import { useTranslation } from "react-i18next";
// import element
import { SelectClassic } from "../selects/SelectClassic";

interface IFPagination {
	background: string;
	arrowBackColor: string;
	arrowFrontColor: string;
	textColor: string;
	rowOptions: {
		value: string | number;
		text: string;
	}[];
	behind(e: any): void;
	following(e: any): void;
	selectChange(e: any): void;
	pageNumber: any;
	rowsOfPage: any;
	totalRecords: any;
}

const defaultProps = {
	background: "bg-gray-800",
	arrowBackColor: "",
	arrowFrontColor: "",
	textColor: "",
	rowOptions: null,
	behind: null,
	following: null,
	selectChange: null,
	pageNumber: 1,
	rowsOfPage: 10,
	totalRecords: 0,
};

export const Pagination = (props: IFPagination) => {
	const pages = props.totalRecords / props.rowsOfPage;
	const [t, i18n] = useTranslation("table");

	const num = parseInt(pages.toFixed());

	return (
		<>
			<div className={`${props.background} pagination__container`}>
				{props.pageNumber !== 0 && props.pageNumber !== 1 && (
					<button
						className={`w-7 rounded-md mx-3 ${props.arrowBackColor}`}
						onClick={() => props.behind(pages)}
					>
						&larr;
					</button>
				)}

				<span className={`py-1 font-semibold ${props.textColor}`}>
					{`${t("PAGINATION.PAGINATION_PAGE")} ${props.pageNumber} -`}{" "}
					{num > 0 ? num : num + 1}
				</span>
				{props.pageNumber !== num && num !== 0 && (
					<button
						className={`w-7 rounded-md mx-3 ${props.arrowFrontColor}`}
						onClick={() => props.following(pages)}
					>
						&rarr;
					</button>
				)}
				<SelectClassic
					classes="ml-6"
					onChange={props.selectChange}
					options={props.rowOptions}
				/>
			</div>
		</>
	);
};

Pagination.defaultProps = defaultProps;
