
//import store
import { dashboardMutations } from "../../store/dashboard/_dashboard.main";
// Import image
import notFound from "../../assets/img/imgNotfound.jpeg";

interface IFDashboardImages {
  url: string;
  data: any;
}

//valor x default
const defaultProps = {
  url: notFound,
  data: null,
};

export const DashboardImages = (props: IFDashboardImages) => {
  const { url, data, bgColor } = props.data;

  const arrData = Object.keys(data);

  const color = (payload: any) => {
    let setColor: any = {
      yellow: "bg-yellow-400",
      red: "bg-red-500",
      green: "bg-primary",
    };
    return setColor[payload];
  };

  // Constants
  const { setShowImage } = dashboardMutations();

  return (
    <>
      <div
        className="dashboard-image__modal--one"
        onClick={() => setShowImage(false)}
      >
        <div className="dashboard-image__modal--two">
          {/*content*/}
          <div className="dashboard-image__modal--three">
            {/*body*/}
            <div className="dashboard-image__modal--body">
              <img
                src={`data:image/jpeg;base64,${url}` || notFound}
                alt="imgUrl"
                className="dashboard-image__modal--photo"
              />
              <div>
                {arrData.map((payload: any) => (
                  <p key={payload} className={`font-bold capitalize b`}>
                    {payload}:
                    <span
                      className={`dashboard-image__modal--badge ${
                        payload === arrData[arrData.length - 1]
                          ? color(bgColor)
                          : ""
                      }`}
                    >
                      {data[payload]}
                    </span>
                  </p>
                ))}
              </div>
            </div>
            {/*footer*/}
          </div>
        </div>
      </div>
    </>
  );
};
DashboardImages.defaultProps = defaultProps;
