
export const SkeletonsCardConsumer = () => {
  return (
    <>
      <div className="skeleton-box bg-coolGray-300 card-consumer">
        <div className="card__contain">
          <div className="skeleton-box card__image card__image--small"></div>
          <div className="card__id">
            <span className="skeleton-box w-7/12 h-3 mb-1 card__id--title"></span>
            <span className="skeleton-box w-2/6 h-3 card__id--title"></span>
          </div>
        </div>
      </div>
    </>
  );
}

export default SkeletonsCardConsumer;
