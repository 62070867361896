import React, { useState } from 'react'

// I18Next Import
import { useTranslation } from "react-i18next";

//Components import
import { ButtonClassic, FormClassic, InputDate, InputClassic } from "../../elements/_elements.main";

//Store import
import { dashboardState } from "../../store/dashboard/_dashboard.main";

//Types import
import { FILTER_CHECK_TYPE } from "../../types/types.dashboards";

import { dateStringTransform } from "../../utils/utils.dates";

interface IFDashboardResumeFilter{
    arrChart:{
        startDate:string;
        endDate:string;
    }[];
    type:string;
    handleSubmit:(e:any)=>void;
}
export const DashboardResumeFilter = (props:IFDashboardResumeFilter) => {

    const { loadingButtonCharts } = dashboardState()

    //I18Next
    const [t, i18n] = useTranslation("dashboard");

    const [filterCheck, setFilterCheck] = useState<string>(props.type)

    const toDay = new Date()
    let initialState = {
        startDate: dateStringTransform(toDay),
        endDate: dateStringTransform(toDay),
    }
    const [dates, setDates] = useState(initialState)

    const handleOptionChange = (payload:React.ChangeEvent<HTMLInputElement>) => {
        setFilterCheck(payload.target.value)
        if(payload.target.value !== FILTER_CHECK_TYPE.TOTALS){
            setDates(initialState)
        }
    }

    const handleSubmit = () => {

        let setData = {}

        if(!filterCheck.includes(FILTER_CHECK_TYPE.TOTALS)){
            setData = {
                startDate: dates.startDate,
                endDate: `${dates.endDate} 23:59:59`,
            }
        }

        props.handleSubmit(setData)
    }

  return (
    <section>
        <FormClassic onSubmit={ handleSubmit }>
            <div className='dashboard-resumen-filter__container'>
                <label className='text-base' htmlFor="today">{t("DASHBOARD_FILTERS.TODAY_DATA")}</label>
                <input
                    id="today"
                    type="radio"
                    name="filters"
                    value="today"
                    onChange={ handleOptionChange }
                    checked={ filterCheck === FILTER_CHECK_TYPE.TODAY }
                    className="dashboard-resumen-filter__input"
                />
                <label className='text-base' htmlFor="totals">{t("DASHBOARD_FILTERS.TOTAL_DATA")}</label>
                <input
                    id="totals"
                    type="radio"
                    name="filters"
                    value="totals"
                    onChange={ handleOptionChange }
                    checked={ filterCheck === FILTER_CHECK_TYPE.TOTALS }
                    className="dashboard-resumen-filter__input"
                />
                <label className='text-base' htmlFor="dates">{t("DASHBOARD_FILTERS.DATA_BY_DATE_RANGE")}</label>
                <input
                    id="dates"
                    type="radio"
                    name="filters"
                    value="dates"
                    onChange={ handleOptionChange }
                    checked={ filterCheck === FILTER_CHECK_TYPE.DATES }
                    className="dashboard-resumen-filter__input"
                />
                <ButtonClassic
                    type="submit"
                    width=""
                    height="h-10"
                    loading={ loadingButtonCharts }
                    text={t("DASHBOARD_RESUME.SEARCH")}
                    onClick={()=>{}}
                />
            </div>
            {
                filterCheck === FILTER_CHECK_TYPE.DATES &&
                <div className='flex gap-3 my-3'>
                    <div>
                        <InputDate name="startDate" value={ dates.startDate } onChange={(e)=>{setDates({...dates, startDate: e.target.value})}}/>
                    </div>
                    <div>
                        <InputDate name="endDate" value={ dates.endDate } onChange={(e)=>{setDates({...dates, endDate: e.target.value})}}/>
                    </div>
                </div>
            }
        </FormClassic>
    </section>
  )
}
