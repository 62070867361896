import create from "zustand";
import { persist } from "zustand/middleware";

export const userState = create(persist(
        () => ({
            user: null,
            attempts: null
        }),
        {
            name: "credentials", // unique name
        }
    )
)

