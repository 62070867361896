import { useEffect, useState } from "react";
// I18next 
import { useTranslation} from "react-i18next";
// Elements imports
import { InputClassic, InputPassword, ButtonClassic, ButtonText, FormClassic } from "../../elements/_elements.main";
// Store import
import { loginState, loginMutations, loginActions } from "../../store/login/_login.main";
// Images imports
import logoIdenti5ID from "../../assets/identi5.svg";

export const LoginSignIn = () => {
  // I18Next
  const [t,i18n] = useTranslation("login")
  // Data
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // State
  const { loadingSignIn } = loginState();
  // Mutations
  const { setViewRecovery,setRecoveryEmail } = loginMutations();
  // Actions
  const { signIn } = loginActions();

  useEffect(() => {
    if (loadingSignIn) setPassword("");
    setRecoveryEmail(email)
  }, [loadingSignIn,email]);

  
  return (
    <>
      <div className="login-signin">
        <div className="w-full">
          <img
            src={logoIdenti5ID}
            alt="logoLogin"
            className="login-signin__logo"
          />
          <FormClassic onSubmit={() => signIn({ email, password })}>
            <InputClassic
              autoFocus={true}
              classes="mb-3"
              value={email}
              placeholder={t("LOGIN.INPUT_EMAIL")}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
            />
            <InputPassword
              value={password}
              placeholder={t("LOGIN.INPUT_PASS")}
              onChange={(e) => setPassword(e.target.value)}
              classes="mb-4"
            />
            <ButtonClassic
              loading={loadingSignIn}
              type="submit"
              onClick={() => { }}
              text={t("LOGIN.SECTION_BUTTON")}
              classes=" mb-7 block"
            />
          </FormClassic>
          <ButtonText
            color="secondary"
            text={t("LOGIN.FORGOT_PASSWORD")}
            onClick={() => setViewRecovery(true)}
            classes="font-light "
          />
        </div>
      </div>
    </>
  );
};
