//peticion
import create from "zustand";
// Types import
import { IFTestingState } from "../../types/types.consumers";

export const consumersState = create<IFTestingState>(() => ({
    consumers: null ,
    loadingGetConsumers: false,
    dataConsumer: null,
    eventsConsumer: null,
    loadingGetDataConsumer: false,
    viewDataConsumer: false,
    currentOption:0
}))