import moment from "moment";
import { useTranslation } from "react-i18next";
const es = {
	months:
		"Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre".split(
			"_"
		),
	monthsShort:
		"Ene._Feb._Mar._Abr._May._Jun._Jul._Ago._Sept._Oct._Nov._Dic.".split("_"),
	weekdays: "Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado".split("_"),
	weekdaysShort: "Dom_Lun_Mar_Mier_Jue_Vier_Sab".split("_"),
	weekdaysMin: "Do_Lu_Ma_Mi_Ju_Vi_Sa".split("_"),
};
const en = {
	months:
		"January_February_March_April_May_June_July_August_September_Octubre_November_December".split(
			"_"
		),
	monthsShort:
		"Jan._Feb._Mar._Apr._May._Jun._Jul._Aug._Sep._Oct._Nov._Dec.".split("_"),
	weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split(
		"_"
	),
	weekdaysShort: "Sun._Mon._Tues._Wed._Thurs._Fri._Sat.".split("_"),
	weekdaysMin: "Sun._Mon._Tues._Wed._Thurs._Fri._Sat.".split("_"),
};

// Utilidades
type TypeFormatDate =
	| "dddd"
	| "DD/MM/YYYY"
	| "DD/MM/YYYY HH:mm"
	| "DD/MM/YYYY HH:mm:ss"
	| "dddd, MMMM Do YYYY"
	| "ddd, MMMM Do YYYY"
	| "DD/MM/YYYY hh:mm A"
	| "DD/MM/YYYY hh:mm:ss a"
	| "DD/MM/YYYY HH:mm:ss a"
	| "MM/YYYY"
	| "WW/YYYY"
	| "MMMM Do YYYY, h:mm:ss a"
	| "HH:mm"
	| "HH:mm:ss"
	| "h:mm:ss a"
	| "YYYY-MM-DDTHH:mm:ss"
    | "DD MMM YYYY, h:mm a"
	| "DD MMMM YYYY, h:mm a"
	| "YYYY";

const useFormatDate = (formats?: {
	formatIn?: TypeFormatDate;
	formatOut?: TypeFormatDate;
	verificateActualDate?: boolean;
}) => {
	const [t, i18n] = useTranslation("app");
	moment.locale("es", i18n.language === "en" ? en : es);
	const formatIn = formats?.formatIn || "DD-MM-YYYY";
	const formatOut = formats?.formatOut || "DD/MM/YYYY";

	const format = (value?: string | moment.Moment) => {
		let date = null;

		if (
			moment(value).format("DD/MM/YYYY") === moment().format("DD/MM/YYYY") &&
			formats?.verificateActualDate
		) {
			date = "Hoy";
		} else if (
			moment(value).format("DD/MM/YYYY") ===
				moment().subtract(1, "days").format("DD/MM/YYYY") &&
			formats?.verificateActualDate
		) {
			date = "Ayer";
		} else {
			date = value ? moment(value, formatIn).format(formatOut) : undefined;
		}

		return date;
	};

	const parser = (value?: string | moment.Moment): string | undefined =>
		value ? moment(value, formatOut).format(formatIn) : undefined;

	return { format, parser };
};

export { useFormatDate };
