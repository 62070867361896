import { useEffect, useState } from "react";
// I18Next Import
import { useTranslation } from "react-i18next";
// Store import
import { dashboardState,dashboardAction, dashboardMutations } from "../../store/dashboard/_dashboard.main";

// Components import
import { Pagination, ButtonClassic } from "../../elements/_elements.main";
import { DashboardFilter } from "./DashboardFilter"
import { DashboardImages } from "./DashboardImages";

//Types import
import { COLORS_TYPE } from "../../types/types.dashboards";

//Utils import
import { mapTableFilter } from "../../utils/utils.map";
import { useFormatDate } from "../../utils/utils.date";

// Assets import
import negative from "../../assets/icons/events/face-negative.svg";
import neutral from "../../assets/icons/events/face-neutral.svg";
import successful from "../../assets/icons/events/face-successful.svg";
import { DashboardModalMap } from "./DashboardModalMap";
import { Table } from "../../elements/tables/Table";

export const DashboardTable = () => {
  //11/08/2022
  //I18Next
  const [t, i18n] = useTranslation("table");

  const [result, setResult] = useState({})
  const [search, setSearch] = useState<null | {}>(null)

  // Constants
  const { events, totalEvents, showImage, pageNumber, rowsOfPage, searchInEvents, originalData } = dashboardState();
  const { requestImage, loadTable } = dashboardAction();
  const { setShowImage, setPageNumber, setRowsOfPage } = dashboardMutations();
  const [showMap, setShowMap] = useState<boolean>(false)
  const [currentData, setCurrentData] = useState()

  // table title
  const header = [
    `${t('TABLE.TABLE_IMAGE')}`,
    `${t('TABLE.TABLE_USER')}`,
    `${t('TABLE.TABLE_OBSERVATIONS')}`,
    `${t('TABLE.TABLE_DATE')}`,
    `${t('TABLE.TABLE_PROCESS_USER')}`,
    `${t('TABLE.TABLE_PROCESS')}`,
    `${t('TABLE.TABLE_RESULT_PROCESS')}`,
    `${t('TABLE.TABLE_DETAIL')}`,
  ];

  const { format: formatDate } = useFormatDate({
		formatIn: "YYYY-MM-DDTHH:mm:ss",
		formatOut: "DD MMM YYYY, h:mm a",
	});

  const imagesType: any = {
    Spoofing: negative,
    Successful: successful,
    Error: negative,
    HumanDetection: neutral,
    DocumentError: neutral,
    DeviceDetection: neutral,
    AccesoriesRecognition: neutral,
    FaceRecognition: neutral,
    DocValidationError: negative,
    DocReadingError: negative,
    DocPhotoReadingError: negative,
    PhotoValidationError: negative,
    ValidationError: negative,
    NotMatch:negative,
    AppError: negative
  }
  const tableRows: any = [];

  events?.forEach((element: any) => {
    delete element.image
    delete element.complete
    delete element.comparePhoto
    delete element.fkProcessId
    delete element.fkUserAppId
    delete element.fkEventTypeId
    delete element.faceId
    delete element.timeSeconds
    delete element.timeMilliseconds
    delete element.eventLocations
    delete element.device

    const tableColums:any= [];
    Object.values(element).forEach((item: any) => {
      tableColums.push(item);
    });
    tableRows.push(tableColums)
  });

  const modalImage = (data: any) => {
    requestImage({ "id": data.id, "UserId": data.userAppId })
      .then((resp: any) => {
        setResult({
          data: {
            [`${t('TABLE.TABLE_IMAGE_TEXT_PROCESS')}`]: data.process,
            [`${t('TABLE.TABLE_IMAGE_TEXT_DATE')}`]: formatDate(data.date),
            [`${t('TABLE.TABLE_IMAGE_TEXT_RESUME')}`]: data.result
          },
          url: resp.image,
          bgColor: (COLORS_TYPE as any)[data.result]
        })
        setShowImage(true)
      });
  }

  //Data for the Pagination
  const options = [
    { value: '', text: `${t("PAGINATION.EMPTY_SELECT")}` },
    { value: 10, text: "10" },
    { value: 20, text: "20" },
    { value: 30, text: "30" },
  ];

  const behind = () => { 
    let pag = 1  
    if (pageNumber > pag) {
      setPageNumber(pageNumber - 1)
    }
  }  
  
  const following = (payload:any) => {   
    if (pageNumber <= payload) {
      setPageNumber(pageNumber + 1)
    }     
  }

  const handleChange = (payload: any) => {
    const minimumPaging = 10
    const getValue = payload.target.value ? payload.target.value : minimumPaging
    setRowsOfPage(getValue)
  }

  const handleSubmit = (payload?:any) => {
    setPageNumber(1)
    setSearch(payload)
  }
  
  useEffect(()=>{
    if(search){
      const dataToSearch = {
        ...mapTableFilter(searchInEvents!),
        PageNumber: pageNumber,
        RowsOfPage: rowsOfPage
      }
      
      loadTable(dataToSearch)
    }
  }, [search, pageNumber, rowsOfPage])

  const ButtonViewDetail = (props: any) => {

    const onClick = () => {
      setCurrentData(props.data)
      setShowMap(true)
    }
    return (<div className="flex mx-4">
      <ButtonClassic width="w-max" classes="m-auto" onClick={onClick} text="Ver detalle"></ButtonClassic>
    </div>)
  }
  const ImgState = (props: any) => {
    return (<div className="flex">
      <img
        alt=""
        src={imagesType[props?.data?.result]}
        className="dashboard-table__img-state"
        onClick={() => { modalImage(props?.data) }}
      />
    </div>)
  }

  return (
    <>
      <div className="dashboard-table">
        <div className="dashboard-table__top flex flex--y-center">
          <h2 className="dashboard-table__title">{t('TABLE.TABLE_TITLE')}</h2>
        </div>
        <div className="dashboard-table__content">
          <DashboardFilter handleSubmit={ handleSubmit } />
          <Pagination
            textColor="text-gray-200"
            arrowBackColor="pagination__arrow--color"
            arrowFrontColor="pagination__arrow--color"
            background="bg-gray-800"
            rowOptions={ options }
            selectChange={ handleChange }
            pageNumber={ pageNumber }
            rowsOfPage={ rowsOfPage }
            totalRecords={ totalEvents }
            behind={ behind }
            following={ following }
          />
          <Table
            components={[{ Component: ImgState, position: 0 }, { Component: ButtonViewDetail, position: 8 }]}
            data={originalData ?? []}
            headers={header}
            textPlane={tableRows} />

          {showImage && <DashboardImages data={result} />}
          {showMap && <DashboardModalMap
            data={currentData}
            onClose={() => setShowMap(false)} />}

        </div>
      </div>
    </>
  );
};
