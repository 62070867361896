import React from 'react'

interface IFInputDate {
    required: boolean;
    name: string;
    value: string;
    onChange(e:any):void
}

const defaultProps = {
    name: '',
    value: '',
    required: true,
    onChange: null
}

export const InputDate = (props:IFInputDate) => {
  return <>
        <input
            className="h-10 px-3 rounded-lg"
            type="date"
            name={ props.name }
            onChange={ props.onChange }
            required = { props.required }
            value= { props.value }
        />
    </>
}

InputDate.defaultProps = defaultProps