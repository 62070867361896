// React
import { useState } from "react";
// I18next 
import { useTranslation} from "react-i18next";
// Elements imports
import { ButtonClassic, ButtonIcon, InputClassic, FormClassic, ModalClassic } from "../../elements/_elements.main";
// Store imports
import { loginActions, loginMutations,loginState } from "../../store/login/_login.main";
// Images imports
import iconExit from "../../assets/icons/exit.svg";
// obteniendo dominio 
const domain = window.location.origin;

export const LoginRecovery = () => {
  const url = domain+"/changepassword/";  
    // I18Next
    const [t,i18n] = useTranslation("recovery");
  // state
  const {recoveryEmail} = loginState()
  // Data
  const [email, setEmail] = useState(recoveryEmail);
  //
  // Mutations
  const { setViewRecovery } = loginMutations();
  // Actions
  const { recoveryPassword } = loginActions();

  const handleClick = (payload: any) => {
    payload && setViewRecovery(false);
  };
//////
/////implementar el idioma
/////
///
  return (
    <>
      <ModalClassic size="small" outsideClick={handleClick}>
        <ButtonIcon
          icon={iconExit}
          onClick={() => setViewRecovery(false)}
          color="error"
          width="w-7"
          height="h-7"
          classes="modal__btn-close"
        />
        <h3 className="mb-2">{t("RECOVERY.RECOVERY_TITLE")}</h3>
        <p className="mb-7 text-justify text-gray-700">{t("RECOVERY.RECOVERY_PARAGRAPH")}</p> 
        <FormClassic onSubmit={() => recoveryPassword({email,url})}>
          <InputClassic
            width="full"
            type="email"
            value={email}
            placeholder={t("RECOVERY.INPUT_EMAIL")}
            onChange={(e) => setEmail(e.target.value)}
            classes="mb-3"
          />
          <ButtonClassic width="w-full" type="submit" text={t("RECOVERY.BUTTON")} onClick={() => {}} />
        </FormClassic>
      </ModalClassic>
    </>
  );
};
