import { useEffect, useRef, useState } from 'react';
//Assets import
import success from "../../assets/icons/success.svg";
import warning from "../../assets/icons/warning.svg";
import error from "../../assets/icons/error.svg";
// Styles import
import "../../assets/styles/elements/elements.alerts.css";

interface IFAlertMessageSmall {
    duration: number;
    classes: string;
    type: "success" | "warning" | "error"
    title: string;
    message: string;
    code: number;
    done(e:any):void
}

const defaultProps = {
    duration: 5,
    classes: "",
    type: "warning",
    title: "Write title!",
    message: "Write information message!",
    code: 0,
    done:null
}

export const AlertMessageSmall = (props:IFAlertMessageSmall) => {

    const duration = props.duration * 1000
    const interval = 100
    const part = 100

    const [progress, setProgress] = useState(duration)
    const [show, setShow] = useState('alert-small--show')
    const progressElement = useRef<HTMLProgressElement>(null)

    useEffect(()=>{        
        const progressInterval = setInterval(()=>{
            if(progressElement.current){
                let total = progressElement.current.value - part
                if(total <= 0){
                    stopShowAlert(progressInterval)
                }
                setProgress(total)
            }        
        },interval)
        
        //CleanUp
        return ()=>{}
    },[]);
    const stopShowAlert = (payload:any) => {
        clearInterval(payload)
        setShow('')
        props.done && setTimeout(()=>{ props.done(true) },100)
    };
    const errorCode:any = {
        400: "error",
        500: "error",
        200: "success"
    };
    const iconType:any = {
        "success": {
            icon: success,
            color: "bg-green-400"
        },
        "warning": {
            icon: warning,
            color: "bg-amber-400"
        },
        "error": {
            icon: error,
            color: "bg-red-400"
        }
    };
    //Validate if props code is passed or not
    const type = props.code > 0 ? errorCode[props.code] : props.type;
    const icon = iconType[type].icon

    const color = () => {
        return iconType[type].color
    };
    const title = props.title

  return (
      <div className={`alert-small ${ show }`} onClick={ stopShowAlert }>
        <div className="absolute top-0 right-0 left-0">
            <progress
             ref={ progressElement }
             className="alert-small__progress-bar"
             max={ duration }
             value={ progress }>
            </progress>
        </div>
        <div className="alert-small__content">
            <div>
                <img src={ icon } alt={`Icon ${ type }`} className={`${color()} alert-small__icon`} />
            </div>
            <div className="overflow-hidden">
                <h2 className={`alert-small__title`}>{ title }</h2>
                <p className="alert-small__message">{props.message}</p>
            </div>
        </div>
      </div>
  );
}

AlertMessageSmall.defaultProps = defaultProps
