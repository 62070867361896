// I18Next Import
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
interface IFTable {
    headers: string[],
    textPlane: any[],
    components: { Component: any, position?: number }[],
    messagetableEmpty?: any,
    data: []
}
export const Table = (props: IFTable) => {

    const [t, i18n] = useTranslation("table");
    const [dataProcessed, setDataProcessed] = useState([])

    const processData = () => {
        let arrTemp: any = []
        
        for (let i = 0; i < props.textPlane.length; i++) {
            const newData = props.textPlane[i]
            for (let j = 0; j < props.components.length; j++) {
                newData.splice(props.components[j].position, 0, props.components[j])
            }
            arrTemp.push(newData)            
        }
        setDataProcessed(arrTemp)
    }
    useEffect(() => {
        processData()
    }, [props.textPlane])

    return (<>
        <div className="table__container-table">
            <table>
                <thead>
                    <tr>
                        {props.headers.map((title: any, indTitle: number) => (
                            <th
                                key={`th-${indTitle}`}
                                scope="col"
                            >
                                {title}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {dataProcessed.map((itemData: any, iData: number) => {
                        return (
                            <tr
                                key={`tr-${iData}`}
                            >
                                {itemData?.map((item: any, iText: number) => {
                                     return (<td key={`td-${iText}`}>{typeof item === 'object' && item !== null ? <item.Component data={props.data[iData]} /> : <p>{item}</p>}</td>)
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {props.textPlane?.length === 0 &&
                <div className="table__message--empty">
                    <p className="m-auto">{props.messagetableEmpty ?? `${t('TABLE.TABLE_MESSAGE_EMPTY')}`}</p>
                </div>
            }
        </div>
    </>)
}