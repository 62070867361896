// PrivateRoutes
import React from "react";
// Import router
import { Routes, Route, Navigate } from "react-router-dom";
// Import components
import { Dashboard } from "../views/Dashboard";
import { Settings } from "../views/Settings";

export const RoutesPrivate: React.FC<any> = ({ children }) => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
};
