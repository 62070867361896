// Icons import
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Styles import
import "../../assets/styles/elements/elements.inputs.css";
//Props
interface IFInputClassic {
  icon: any;
  placeholder: string;
  type: string;
  value: string;
  color: string;
  width: string;
  height: string;
  classes: string;
  pattern: string;
  minLength: number;
  maxLength: number;
  required: boolean;
  autoFocus: boolean;
  disabled: boolean;
  readOnly: boolean;
  onChange(e: any): void;
  onInput(e: any): void;
  onBlur(e: any): void;
  onClick: () => void;
  onClickIcon: () => void;
  onFocus:()=> void;
};
//valor x default
const defaultProps = {
  icon: null,
  placeholder: "Text input",
  type: "text",
  value: "",
  color: "primary",
  width: "w-full md:w-72",
  height: "h-12",
  classes: "",
  pattern: null,
  minLength: null,
  maxLength: null,
  required: true,
  autoFocus: false,
  disabled: false,
  readOnly: false,
  onChange: null,
  onClick: null,
  onClickIcon: null,
  onInput: null,
  onBlur: null,
  onFocus:null
};

//component
export const InputClassic = (props: IFInputClassic) => {

  const color = () => {
    const options: any = {
      primary: "input-classic--primary",
      secondary: "input-classic--secondary",
    };
    return options[props.color];
  };

  return (
    <>
      <div
        className={`
          relative
          ${props.width}
          ${props.height}
          ${props.classes}
        `}
      >
        <input
          required={props.required}
          minLength={props.minLength}
          maxLength={props.maxLength}
          autoFocus={props.autoFocus}
          placeholder={props.placeholder}
          pattern={props.pattern}
          type={props.type}
          className={`
            input-classic
            ${props.icon && "input-classic--content"}
            ${color()}
          `}
          value={props.value}
          disabled={props.disabled}
          readOnly={props.readOnly}
          onClick={props.onClick}
          onChange={props.onChange}
          onBlur={props.onBlur}
          onInput={props.onInput}
          onFocus={props.onFocus}
        />
        {props.icon && (
          <FontAwesomeIcon onClick={ props.onClickIcon } icon={props.icon} className={`input-classic__icon ${props.onClickIcon !== null ? 'cursor-pointer': ''}`} />
        )}
      </div>
    </>
  );
};
InputClassic.defaultProps = defaultProps;
