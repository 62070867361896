import React, { useEffect } from 'react'

import { AlertMessagesModal, AlertMessageSmall } from "../_elements.main";

interface IFAlertMessageType{
    content:null | {
        code:number;
        title:string;
        message:string;
        alertType: "toast" | "modal",
        type: "success" | "warning" | "error"
    },
    outsideClick:boolean;
    onClick(e:any):void
}
const defaultProps = {
    content:{
        code: 0,
        title: "Write title!",
        message: "Write information message!",
        alertType: "modal",
        type: "warning",
    },
    outsideClick: true,
    onClick: null
}

export const AlertMessageType = (props:IFAlertMessageType) => {
    
    const content = props.content
    const type = content?.alertType || "toast"

    const alertType:any = {
        toast: <AlertMessageSmall 
                code={ content?.code }
                type={ content?.type }
                title={ content?.title}
                message={ content?.message }
                done={ props.onClick }
            />,
        modal: <AlertMessagesModal
                code={ content?.code }
                type={ content?.type }
                title={ content?.title}
                message={ content?.message }
                outsideClick={ props.outsideClick }
                closeAlert={ props.onClick }
            />,
    }

    useEffect(()=>{
        return ()=>{}
    })

    return(
        <>
            <div className="alert-message-type__container">
                {alertType[type]}
            </div>
        </>
    );
}

AlertMessageType.defaultProps = defaultProps
