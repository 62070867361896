import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
// Import translations json
import {getTranslation} from "./translations/_translation.main"
// Import I18Next
import i18next from "i18next";
import {I18nextProvider} from "react-i18next";
// Import styles
import "./assets/styles/_styles.main.css";
import { App } from "./App";

getTranslation(i18next)

ReactDOM.render(
  <React.StrictMode>    
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
          <App />
      </BrowserRouter>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
