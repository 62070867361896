export enum BUTTON_OPTIONS{
    INDICATORS,
    EVENTS
}

export enum FILTER_CHECK_TYPE{
    TODAY = 'today',
    TOTALS = 'totals',
    DATES = 'dates'
}

export enum ABANDONMENT_CODES{
    FACE_RECOGNITION = 600,
    FACE_VALIDATION = 601,
    DOCUMENTATION = 602
}

export enum COLORS_TYPE{
    Spoofing = 'red',
    Successful = 'green',
    Error = 'red',
    HumanDetection = 'yellow',
    DocumentError = 'yellow',
    DeviceDetection = 'yellow',
    AccesoriesRecognition = 'yellow',
    FaceRecognition = 'yellow',
    DocValidationError = 'red',
    DocReadingError = 'red',
    DocPhotoReadingError = 'red',
    PhotoValidationError = 'red',
    ValidationError = 'red',
    NotMatch='red',
    AppError='red'
}
export enum COLORS_TYPE_BG{
    Spoofing = 'bg-error text-white',
    Successful = 'bg-primary',
    Error = 'bg-error text-white',
    HumanDetection = 'bg-yellow',
    DocumentError = 'bg-yellow',
    DeviceDetection = 'bg-yellow',
    AccesoriesRecognition = 'bg-yellow',
    FaceRecognition = 'bg-yellow',
    DocValidationError = 'bg-error text-white',
    DocReadingError = 'bg-error text-white',
    DocPhotoReadingError = 'bg-error text-white',
    PhotoValidationError = 'bg-error text-white',
    ValidationError = 'bg-error text-white',
    NotMatch='bg-error text-white',
    AppError='bg-error text-white'
}

export interface IFChartStructure {
    chartType:string;
    sunday:number;
    monday:number;
    tuesday:number;
    wednesday:number;
    thursday:number;
    friday:number;
    saturday:number;
    startDate:string;
    endDate:string;
    totalCount:number;
}

export interface IFTableFilter{
    eventID:string;
    userID:string;
    startDate:string;
    endDate: string;
    types: string;
    process:string;
    devices:string;
}

export interface IFDashboards {
    showImage: boolean;
    dataTable: { 
        events: null | {};
        totalRecords: number
    };
    dashboard: null | {};
    searchData: null | [];
    chartTypeList: string[];
    summaryCardData: null | {
        completeCount: string;
        errorCount: string;
        fkClientId: string;
        id: string;
        processCount: string;
        spoofingCount: string;
        userCount: string;
    };
    viewChart:string;
    summaryChartData: null | [];
    usersChartData: null | IFChartStructure;
    processChartData: null | IFChartStructure;
    completedChartData: null | IFChartStructure;
    errorsChartData: null | IFChartStructure;
    spoofingChartData: null | IFChartStructure;
    loadingButtonCharts: boolean;
    loadingButtonFilter:boolean;
    tableData: null | [];
    userDataModel: null | {};
    totalEvents: number;
    events: null | [];
    pageNumber: any;
    rowsOfPage: any;
    eventTypeList: [];
    processTypeList:[];
    devicesList: [];
    modalImageUser: boolean;
    cardDocument:null|{
        dropoutCounter:number,
        dropoutPercent:number,
        eventCounter:number,
    },
    cardRecognition:null|{
        dropoutCounter:number,
        dropoutPercent:number,
        eventCounter:number,
    },
    cardValidation:null|{
        dropoutCounter:number,
        dropoutPercent:number,
        eventCounter:number,
    },
    searchInEvents:null|IFTableFilter,
    originalData:null|[],
    cities:[],
    countries:[],
    departments:[]
}